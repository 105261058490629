<template>
  <v-row class="flex-column text-center">
    <!-- Título principal centrado -->
    <v-col cols="12" sm="12" md="12" class="mx-auto responsiveTitulo">
      <h1 class="btnAzul--text text-xl-h2 font-weight-bold">
        {{ title }}
      </h1>
    </v-col>

    <!-- Contenido del slot a la izquierda del título -->
    <v-col cols="12" sm="12" md="12" class="mx-auto text-left">
      <slot name="leftContent"></slot>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "AppTitleComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    breakpoint() {
      if (this.$vuetify.breakpoint.sm) {
        return "text-h6";
      } else if (this.$vuetify.breakpoint.xs) {
        return "text-h5";
      } else if (this.$vuetify.breakpoint.md) {
        return "text-h4";
      } else if (this.$vuetify.breakpoint.lg) {
        return "text-h4";
      } else if (this.$vuetify.breakpoint.xl) {
        return "text-xl-h2";
      } else {
        return "text-h1";
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 300px) {
  .responsiveTitulo {
    margin-right: 0;
    padding-right: 0;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }
  
}
</style>
