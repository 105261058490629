import {http_client} from "../plugins/http_client";

const url = '/api/v1/perfiles';

const getProfiles = async (filtros = {}) => await http_client(url, filtros)
const createProfile = async (body) => await http_client(url, body, 'post')
const showPorfile = async (idProfile) => await http_client(`${url}/${idProfile}`)
const updateProfile = async (idProfile, body) => await http_client(`${url}/${idProfile}`, body, 'put')
const getNivelesacceso = async (filtros) => await http_client('/api/v1/catalogo/nivel-acceso')
const changeStatus = async (id,body) =>
  await http_client(`${url}/${id}`, body, "delete");
export default {
    getProfiles,
    createProfile,
    showPorfile,
    updateProfile,
    changeStatus,
    getNivelesacceso
}