import {http_client} from "../plugins/http_client";

const getPesajes = async (queryParams = {}) => await http_client('/api/v1/pesaje', queryParams, 'get')
const getPesaje = async (idPesaje) => await http_client(`/api/v1/pesaje/${idPesaje}`)
const getTipoLevantamiento = async (queryParams = {}) => await http_client(`/api/v1/catalogo/tipo-levantamiento`, queryParams, 'get')
const getConversion = async (queryParams = {}) => await http_client('/api/v1/catalogo/unidad-medida', queryParams, 'get')
const createPesaje = async (body) => await http_client('/api/v1/pesaje', body, 'post', {'Content-Type': 'multipart/form-data',} )

//Para la generación de pdf y csv
const getPdf= async (params = {}) => await http_client('api/v1/pesaje/reporte', params)
const getVehiculosPesaje= async (params = {}) => await http_client('api/v1/vehiculo/catalogo', params)
const downloadExcelDocumentFormat = async (params = {}) => {return await http_client(`/api/v1/documentos/excel`,params,"get", {},true, "arraybuffer");};
//Comprobantes
const getComprobantes= async (id, params = {}) => await http_client(`api/v1/pesaje/comprobante/${id}`, params)
const postComprobantesDetail = async (id, body) => await http_client(`api/v1/pesaje/${id}`, body, "post")

export default {
    getPesajes,
    getPesaje,
    getConversion,
    createPesaje,
    getPdf,
    getVehiculosPesaje,
    downloadExcelDocumentFormat,
    getComprobantes,
    postComprobantesDetail,
    getTipoLevantamiento,
}