<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.6673 13.332V26.6654H13.334V13.332H26.6673ZM20.0007 38.1654C17.4794 38.1654 15.1232 37.6877 12.9218 36.7378C10.7011 35.7796 8.77931 34.484 7.14734 32.852C5.51537 31.22 4.21979 29.2983 3.26153 27.0775C2.31163 24.8762 1.83398 22.5199 1.83398 19.9987C1.83398 17.4775 2.31163 15.1212 3.26153 12.9199C4.21979 10.6991 5.51537 8.77736 7.14734 7.14539C8.77931 5.51342 10.7011 4.21784 12.9218 3.25957C15.1232 2.30968 17.4794 1.83203 20.0007 1.83203C22.5219 1.83203 24.8781 2.30968 27.0795 3.25957C29.3002 4.21784 31.222 5.51342 32.854 7.14539C34.4859 8.77736 35.7815 10.6991 36.7398 12.9199C37.6897 15.1212 38.1673 17.4775 38.1673 19.9987C38.1673 22.5199 37.6897 24.8762 36.7398 27.0775C35.7815 29.2983 34.4859 31.22 32.854 32.852C31.222 34.484 29.3002 35.7796 27.0795 36.7378C24.8781 37.6877 22.5219 38.1654 20.0007 38.1654ZM20.0007 36.332C24.5408 36.332 28.4225 34.7452 31.5848 31.5829C34.7471 28.4206 36.334 24.5389 36.334 19.9987C36.334 15.4585 34.7471 11.5768 31.5848 8.41451C28.4225 5.25221 24.5408 3.66536 20.0007 3.66536C15.4605 3.66536 11.5788 5.25221 8.41646 8.41451C5.25416 11.5768 3.66732 15.4585 3.66732 19.9987C3.66732 24.5389 5.25416 28.4206 8.41646 31.5829C11.5788 34.7452 15.4605 36.332 20.0007 36.332Z" :fill="fill" :stroke="fill" stroke-width="2"/>
</svg>

</template>

<script>
export default {
  name: 'parked',
  props:{
    fill:{
      type: String,
      default: '#96A14C'
    }
  }
}
</script>

<style scoped>
svg {
  width: 36px;
  height: 36px;
}
</style>
