<template>
  <div @mouseover="handlerMouse(true)" @mouseleave="handlerMouse(false)">
    <v-navigation-drawer :expand-on-hover="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" 
      :value="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? true : sideBar" fixed app mini-variant-width="70"
      @input="setSideBar($event)" width=""
      style="border-radius: 0px 15px 15px 0px; box-shadow: 2px 2px 15px #cecece; z-index: 1100;" color="#ffffff">
  
        <v-list-item two-line>
          <v-img  v-show="expand"  class="mt-15 mb-15" :src="img.src"></v-img>
        </v-list-item>

      <v-list dense nav class="mt-3">
        <template v-for="(item, i) in menu">
          <v-list-group v-if="item.childrens && item.childrens.length > 0" :key="i"
            :prepend-icon="item.icono? item.icono : 'mdi-folder'" no-action style="margin-bottom: 30px">
            <template v-slot:activator>
              <v-tooltip top :disabled="!sideBar" color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title class="styleMenuGeneral" v-bind="attrs" v-on="on">
                    <div class="d-flex text-center">
                      {{ item.nombre }}
                    </div>
                  </v-list-item-title>
                </template>
                <span>{{ item.nombre }}</span>
              </v-tooltip>
            </template>

            <v-list-item v-for="(child, j) in item.childrens" :key="j" :to="!isExternal(child.uri) ? child.uri : null"
              @click="handleClick(child.uri)" active-class="black--text" style="margin-bottom: 20px">
              <v-tooltip top :disabled="!sideBar" color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon v-bind="attrs" v-on="on">
                    <v-icon class="styleIconMenu black-icon" color="#000000">{{ child.icono || 'mdi-file' }}</v-icon>
                  </v-list-item-icon>
                </template>
                <span>{{ child.nombre }}</span>
              </v-tooltip>

              <v-tooltip top :disabled="!sideBar" color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title class="" style="
                    color: #313945;
                    font-size: 16px !important;
                    font-weight: 500 !important;
                    line-height: 1rem;
                  " v-bind="attrs" v-on="on">
                    {{ child.nombre }}
                  </v-list-item-title>
                </template>
                <span>{{ child.nombre }}</span>
              </v-tooltip>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :key="i" :to="!isExternal(item.uri) ? item.uri : null" @click="handleClick(item.uri)"
            active-class="selectedBgPurple--text" style="margin-bottom: 20px">
            <v-tooltip top :disabled="!sideBar" color="selectedBgPurple">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon v-bind="attrs" v-on="on">
                  <v-icon class="" color="#313945">{{ item.icono }}</v-icon>
                </v-list-item-icon>
              </template>
              <span>{{ item.nombre}}</span>
            </v-tooltip>

            <v-tooltip top :disabled="!sideBar" color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title class="" style="
                  color: #313945;
                  font-size: 16px !important;
                  font-weight: 500 !important;
                  line-height: 1rem;
                " v-bind="attrs" v-on="on">
                  {{ item.nombre }}
                </v-list-item-title>
              </template>
              <span>{{ item.nombre }}</span>
            </v-tooltip>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "AppAsideBar",
  props: {
    menu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      expand: false,
      img: new Image(),
    }
  },
  beforeMount() {
    this.img.src = require('@/assets/img/SMGIRS.svg'); // Cargar imagen
  },
  methods: {
    ...mapMutations("utils", ["setSideBar"]),
    isExternal(uri) {
      return uri.startsWith("http");
    },
    handleClick(uri) {
      if (this.isExternal(uri)) {
        window.open(uri, "_blank");
      }
    },
    handlerMouse(change) {
      this.expand = change;
      
    }
  },
  computed: {
    ...mapState("utils", ["sideBar"]),
  },
  
};
</script>

<style >
.styleIconMenu {
  font-size: 21px;
}

.styleMenuGeneral {
  font-size: 19px !important;
}

.black-icon {
  color: black;
}

.v-icon.v-icon {
  color: black;
}
</style>
