import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogo/unidad-medida";

const getUnidadMedida = async (filtro = {}) => await http_client(url, filtro, "get");

export default {
  getUnidadMedida,
};

