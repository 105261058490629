import Vue from "vue";
import "./plugins/axios";
import "./plugins/http_client";
import "./plugins/utils";
import "./plugins/security"
import App from "./App.vue";
import router from "./router";
import './services'
import store from "./store";
import vuetify from "./plugins/vuetify";
import vMask from "v-mask";
import "./assets/scss/variables.scss";


import 'leaflet/dist/leaflet.css';
import {LControl, LControlZoom, LGeoJson, LIcon, LMap, LMarker, LPolyline, LPopup, LTileLayer, LIconDefault, LCircle, LTooltip } from 'vue2-leaflet';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import 'leaflet-rotatedmarker'
import 'leaflet.markercluster'


import LSlideMarker from 'vue2-leaflet-slidemarker'
import L, {Icon} from 'leaflet'
window.L = L;
import momentTime from "moment-timezone";
import loadComponentes from "@/plugins/loadComponents";
import Vuelidate from 'vuelidate'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import InnerImageZoom from 'vue-inner-image-zoom';

// import LMovingRotatedMarker from 'vue2-leaflet-moving-rotated-marker'
// Vue.component('l-moving-rotated-marker', LMovingRotatedMarker)
Vue.component( 'inner-image-zoom', InnerImageZoom)
Vue.component('l-slide-marker', LSlideMarker)
// import LMovingMarker from 'vue2-leaflet-movingmarker'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

import vMarkerCluster from 'vue2-leaflet-markercluster'


Vue.component('l-map', LMap);
Vue.component('l-geo-json', LGeoJson)
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-circle', LCircle );
Vue.component('l-marker', LMarker);
Vue.component('l-polyline', LPolyline);
Vue.component('l-icon', LIcon)
Vue.component('l-popup', LPopup)
Vue.component('l-tooltip', LTooltip)
Vue.component('l-control', LControl)
Vue.component('l-control-zoom', LControlZoom)
Vue.component('l-icon-default', LIconDefault)
Vue.component('l-marker-cluster', vMarkerCluster)

/* Establish Connection */

// const token = localStorage.getItem("token");
// const socketConnection = SocketIO(`${process.env.VUE_APP_API_URL}`, {
//   auth: {
//     token: token
//   }
// });

// Vue.use(new VueSocketIO({
//       debug: false,
//       connection: socketConnection,
//       autoConnect: false

//     })
// );
// const socket = io('http://100.88.131.45:8000')
// Vue.use(VueSocketIOExt, socket);

const moment = require("moment");
require("moment/locale/es");
Vue.prototype.moment = moment;

Vue.use(vMask);
Vue.use(require("vue-moment"), {
  moment,
  momentTime,
});

loadComponentes(Vue);

Vue.config.productionTip = false;

Vue.use(Vuelidate)

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    const token = localStorage.getItem("token");
    if (token) store.commit("setToken", token);
  },
  render: (h) => h(App),
}).$mount("#app");
