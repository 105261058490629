import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "../assets/scss/index.scss";
import LogoAtencionCiudadana from "@/assets/img/logo_sistema_atencion_ciudadana.vue";
import cleanIcon from "@/assets/img/clean-icon.vue";
import iconCircle from "@/assets/img/iconCircle.vue";
import mapDireccion from "@/assets/img/mapDireccion.vue";
import mapDireccion2 from "@/assets/img/mapDireccion2.vue";
import es from "vuetify/es5/locale/es";
import toogle_on from '@/assets/img/toogle_on.vue'
import toogle_off from '@/assets/img/toogle_off.vue'
import checked from '@/assets/img/checked.vue'
import parking from '@/assets/img/parking.vue'
import parked from '@/assets/img/parked.vue'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      'logo': { // name of our custom icon
        component: LogoAtencionCiudadana, // our custom component
      },
      'cleanIcon': { // name of our custom icon
        component: cleanIcon, // our custom component
      },
      'mapDireccion': { // name of our custom icon
        component: mapDireccion, // our custom component
      },
      'mapDireccion2': { // name of our custom icon
        component: mapDireccion2, // our custom component
      },
      'iconCircle': { // name of our custom icon
        component: iconCircle, // our custom component
      },
      'toogle_on': { // name of our custom icon
        component: toogle_on, // our custom component
      },
      'toogle_off': { // name of our custom icon
        component: toogle_off, // our custom component
      },
      'checked': { // name of our custom icon
        component: checked, // our custom component
      },
      'parking': { // name of our custom icon
        component: parking, // our custom component
      },
      'parked': { // name of our custom icon
        component: parked, // our custom component
      },
    }
  },
  theme: {
    themes: {
      //Se definen colores globales para el tema claro
      light: {
        blueMinsal: "#175EFB",
        sacColor: '#30A4F1',
        blueGrayMinsal: "#6A83BE",
        blueDarkMinsal: "#2D52A8",
        bgMinsal: "#F4F7FD",
        bgWhite: "#FFFFFF",
        primary: "#313945",
        btnAzul: "#1C1E4D",
        selectedBgPurple: "#697182",
        blueText: "#313945",
        iconsColor: "#1C1B1F",
        rojo: '#F66765',
        amarillo: '#FFE854',
        verde_limon: '#C4ED87',
        verde_fuerte: '#00A86B',
        gris: '#C3C6C9'
      },
      dark: {
        bgMinsal: "#2f3032",
        blueMinsal: "#175EFB",
        blueGrayMinsal: "#6A83BE",
        blueDarkMinsal: "#2D52A8",
        primary: "#175EFB",
        // primary: {
        //     base: colors.purple.base,
        // }
      },
    },
  },

  lang: {
    locales: { es },
    current: "es",
},
});
