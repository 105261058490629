import {http_client} from "../plugins/http_client";

const getDenuncia = async (queryParams = {}) => await http_client('/api/v1/denuncias/list', queryParams, 'get')
const getDenunciasHijas = async (queryParams = {}) => await http_client('/api/v1/denuncias/list-hijos', queryParams, 'get')
const getDetalleDenuncia = async (id) => await http_client(`/api/v1/denuncias/${id}`)
const getDenunciasAgrupadas = async (id) => await http_client(`/api/v1/denuncias/${id}/agrupadas`, {}, 'get')
const getBitacoraDenuncia = async (id) => await http_client(`/api/v1/denuncias/bitacora/${id}`, {}, 'get')
const getDenunciaRelacionadas = async (id) => await http_client(`/api/v1/denuncias/casos_semejantes/${id}`, {}, 'get')
const agruparDenuncias = async (id, body) => await http_client(`/api/v1/denuncias/agrupar/${id}`, body, 'post')
const saveBitacora = async (id, body) => await http_client(`/api/v1/denuncias/${id}/bitacora`, body, 'post', {'Content-Type': 'multipart/form-data'})
const saveResolucion = async (id, body) => await http_client(`/api/v1/denuncias/${id}/resolucion`, body, 'post', {'Content-Type': 'multipart/form-data'})
const getBitacorasDenuncias = async (id) => await http_client(`/api/v1/denuncias/${id}/bitacora`, {}, 'get')
const getInstituciones= async (id, queryParams = {}) => await http_client(`/api/v1/denuncias/reasignar/instituciones/${id}`, queryParams, 'get')
const getBitacorasHistoricas = async (id) => await http_client(`/api/v1/denuncias/${id}/reasignacion/historico`, {}, 'get')
const saveReasignarCaso= async (id, body) => await http_client(`/api/v1/denuncias/${id}/reasignacion`, body, 'post', {'Content-Type': 'multipart/form-data'})
const getDocument= async (queryParams) => await http_client(`api/v1/denuncias/descargar-bitacora?url=${queryParams}`, 'get')
// const getGenero = async (queryParams = {}) => await http_client('/api/v1/genero', queryParams, 'get')
// const createLibro = async (body) => await http_client('/api/v1/libros', body, 'post')
// const updateLibro = async (idLibro, body) => await http_client(`/api/v1/libros/${idLibro}`, body, 'put')
// const deleteLibro = async (idLibro) => await http_client(`/api/v1/libros/${idLibro}`, {}, 'delete')


export default {
    getDenuncia,
    getDenunciasHijas,
    getDetalleDenuncia,
    getDenunciasAgrupadas,
    getBitacoraDenuncia,
    getDenunciaRelacionadas,
    agruparDenuncias,
    saveBitacora,
    saveResolucion,
    getBitacorasDenuncias,
    getInstituciones,
    getBitacorasHistoricas,
    saveReasignarCaso,
    getDocument,
    // getGenero,
    // createLibro,
    // updateLibro,
    // deleteLibro,
    // createRole,
    // updateRole,
    // deleteRole
}