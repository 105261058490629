import {http_client} from "@/plugins/http_client";

const url= "/api/v1/catalogo/gondola";
const urlGondola='/api/v1/vehiculo/gondolas'
// const urlGondolavEHICULO='/catalogo/gondola?'

const getGondolasVehiculo=async(params = {})=>
    await http_client(url,params,"get");

const saveGondolaByVehicle=async (data)=>
    await http_client(`${urlGondola}`, data, "post");

const getGondolasByVehiculo=async (idVehicle)=>
  await http_client(url, {id_vehiculo:idVehicle},'get')

const deleteGondolaByVehicle = async (id) =>
  await http_client(
    `${urlGondola}/${id}`,
    {},
    "delete"
  );

export default {
    getGondolasVehiculo,
    getGondolasByVehiculo,
    saveGondolaByVehicle,
    deleteGondolaByVehicle,
}