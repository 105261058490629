<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :show-select="showSelect"
        v-model="selected"
        :headers="headers"
        :items="items"
        :hide-default-footer="ocultar_footer"
        @update:options="sortFunction"
        :mobile-breakpoint="mobile_breakpoint"
        :disable-pagination="tiene_paginacion"
        no-data-text="No hay datos para mostrar"
        class="elevation-1 custom-data-table"
      >
        <!-- Exponemos los slots del v-data-table en el componente padre -->
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data"></slot>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "DataTableComponent",
  components: {},

  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    tiene_paginacion: {
      type: Boolean,
      default: true,
    },
    ocultar_footer: {
      type: Boolean,
      default: true,
    },
    show_actions: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      required: false,
      default() {
        return [
          // Estructura de las acciones dinamicas
          // {
          //   tooltip: {
          //     text: "Enviar Notificación",
          //     color: "gtPrimary",
          //   },
          //   icon: {
          //     color: "gtPrimary",
          //     name: "mdi-send-check",
          //   },
          //   eventName: "send",
          // },
          // {
          //   tooltip: {
          //     text: "Ver",
          //     color: "gtPrimary",
          //   },
          //   icon: {
          //     color: "gtPrimary",
          //     name: "mdi-eye",
          //   },
          //   eventName: "show",
          // },
        ];
      },
    },
    mobile_breakpoint: {
      type: String,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selected: [],
  }),

  methods: {
    // Función que emite el evento de cambio de pagina
    sortFunction(sortableInfo) {
      this.$emit("sortFunction", sortableInfo);
    },
  },
  watch: {
    selected() {
      this.$emit("selected", this.selected);
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-chip) {
  width: -webkit-fill-available !important;
  display: flex !important;
  justify-content: center !important;
}

// Alinea todos los encabezados de la tablaa
:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr:last-child
      > th.sortable
  ) {
  text-align: center !important;
}

:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr:last-child
      > th.sortable
  ) {
  text-align: center !important;
  font-size: 14px;
}
:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr:last-child
      > th
  ) {
  text-align: center !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr:last-child
      > th
  ) {
  text-align: center !important;
  font-weight: bold;
  font-size: 16px;
}
:deep(
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td
  ) {
  text-align: center !important;
  flex-wrap: wrap;
  // color: #509ed7 !important;
}

:deep(.v-data-table > .v-data-table__wrapper tr:nth-child(odd)) {
  background-color: #ededf1;
}

:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr
      > th
  ) {
  background-color: #ffff !important;
  color: #000000;
}

:deep(.v-data-table > .v-data-table__wrapper > table > tbody > tr > td) {
  text-align: center !important;
  font-size: 14px !important;
  color: #000000de;
}
</style>
