<template>
  <v-responsive v-model="dialogVisible" class="custom-modal-container" :max-width="maxWidth" width="450">
    <v-card class="custom-modal-card" color="white" :style="{ 'max-height': maxHeight + 'px', 'overflow-y': 'auto' }">
      <v-system-bar window dark :height="systemBarHeight" color="#888888" class="sticky-system-bar">
        <span class="ml-2 white--text font-weight-regular font-weight-bold" :class="dynamicTitleBar" style="font-size: 16px;">
          {{ title }}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon dark :large="isLargeButton" :small="isSmallButton" :class="[buttonClass, 'd-md-none']" @click="hideDialog">
          <v-icon color="white" style="font-size: 24px;">
            {{minimizar ? 'mdi-window-maximize' : 'mdi-window-minimize'}}
          </v-icon>
        </v-btn>
        <v-btn icon dark :large="isLargeButton" :small="isSmallButton" :class="buttonClass" @click="closeDialog">
          <v-icon color="white" style="font-size: 24px;">
            mdi-close
          </v-icon>
        </v-btn>
      </v-system-bar>
      <div v-show="!minimizar">
        <section class="container px-2" style="font-size: 14px; padding-top: 16px;">
          <slot name="content" />
        </section>
        <div class="actions-buttons pl-5 pb-5" :class="classButtons">
          <slot name="actions" />
        </div>
      </div>
    </v-card>
  </v-responsive>
</template>


<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: String,
    maxWidth: {
      type: [Number, String],
      default: 550,
    },
    maxHeight: {
      type: [Number, String],
      default: 600,
    },
    classButtons: {
      type: String,
      default: "d-flex justify-end",
    },
    systemBarHeight: {
      type: String,
      default: "50px",
    },
    isLargeButton: Boolean,
    isSmallButton: Boolean,
    buttonClass: String,
  },
  data() {
    return {
      dialogVisible: this.value,
      minimizar : false
    };
  },
  computed: {
    breakpoint() {
     if (this.$vuetify.breakpoint.lg) {
      this.minimizar = false
        return true;
      } else if (this.$vuetify.breakpoint.xl) {
        this.minimizar = false
        return true;
      } else if (this.$vuetify.breakpoint.md) {
        this.minimizar = false
        return true;
      }else{
        return false
      }
    },
    dynamicTitleBar() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "";
        case "sm":
          return "text-body-2"; // Ejemplo de clase de tamaño de texto pequeño
        case "md":
          return "text-body-2";
        case "lg":
          return "text-body-1"; // Ejemplo de clase de tamaño de texto más grande
        case "xl":
          return "text-body-1";
        case "2xl":
          return "text-body-1";
        default:
          return "";
      }
    },
  },
  watch: {
    breakpoint() {},
    value(newValue) {
      this.$emit("input", newValue);
      this.dialogVisible = newValue;
    },
    dialogVisible(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false;
    },
    hideDialog() {
     this.minimizar = !this.minimizar
    },
  },
};
</script>

<style scoped>
.custom-modal-container {
  position: absolute;
  top: 75px;
  left: 20px; /* Ajusta según sea necesario */
  z-index: 1050;
  box-shadow:  1px 1px  1px rgb(167, 167, 167);
}
@media (max-width: 600px) {
  .custom-modal-container {
    position: absolute;
   max-width: 250px !important;
   max-height:6400px !important;
  top: 103px;
  left: 0%; /* Ajusta según sea necesario */
  z-index: 1050;
  }
}
.custom-modal-card {
  border-radius: 0px;
}

.actions-buttons {
  text-align: center;
  margin-top: 15px;
}
.sticky-system-bar {
  position: sticky;
  top: 0;
  z-index: 1000; /* Asegúrate de que la barra esté en un nivel superior */
}
</style>
