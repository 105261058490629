import { http_client } from "@/plugins/http_client";

const url = "/api/v1/public-distritos";

const getDistritos = async (params = {}) =>
  await http_client(url, params, "get");

export default {
  getDistritos,
};
