<!-- TamañoIconos.vue -->
<template>
  <v-row align="center" justify="center">
    <v-col cols="1">
      <v-icon :class="iconClass.large">mdi-checkbox-blank-circle</v-icon>
      <v-icon :class="iconClass.medium">mdi-checkbox-blank-circle</v-icon>
      <v-icon :class="iconClass.small">mdi-checkbox-blank-circle</v-icon>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'iconCircle',
  data() {
    return {
      iconClass: {
        large: 'mdi-15px',
        medium: 'mdi-10px',
        small: 'mdi-5px',
      }
    };
  }
};
</script>

<style scoped>
/* Estilos adicionales según sea necesario */
</style>