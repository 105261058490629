<template>
  <v-row class="justify-center align-center px-16">
    <v-col cols="12">
    <v-img src="@/assets/img/logo_sistema_atencion_ciudadana.png"></v-img>
    </v-col>
    <v-col cols="12">
      <v-progress-linear color="sacColor" indeterminate></v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AppLoader"
}
</script>

<style lang="scss" scoped>


</style>
