<template>
  <v-menu
    ref="menu"
    v-model="menuVisible"
    :close-on-content-click="false"
    
    :return-value.sync="selectedTime"
    transition="scale-transition"
    offset-y
    :max-width="menuWidth"
    :min-width="menuWidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="selectedTime"
        :label="label"
        append-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
        outlined
        :error-messages="errorsHora"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menuVisible"
      v-model="selectedTime"
      full-width
      @click:minute="saveTime"
    ></v-time-picker>
  </v-menu>
</template>

<script>

import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    value: String,
    label: {
      type: String,
      default: "Picker in menu",
    },
    menuWidth: {
      type: String,
      default: "290px",
    },
    isRequired: {
      default: true,
      type: Boolean
    }
  },

  validations: {
    selectedTime: { required }
  },

  computed: {
    selectedTime: {
      get() {
        return this.value; 
      },
      set(newValue) {
        this.$emit("input", newValue); 
      },
    },

    errorsHora () {
      const errors = []
      if (!this.$v.selectedTime.$dirty) return errors
      !this.$v.selectedTime.required &&
        errors.push('Campo obligatorio')
      return errors
    }
  },
  data() {
    return {
      menuVisible: false,
    };
  },
  methods: {
    saveTime() {
      this.$refs.menu.save(this.selectedTime);
    },
  },
};
</script>