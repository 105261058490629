import { http_client } from "@/plugins/http_client";

const url = "/api/v1/vehiculo";
const urlPersonalVehicle = "/api/v1/flota-vehicular";
const urlH = "/api/v1/vehiculo/gps/list/history"

const getVehiculos = async (filtros = {}) =>
  await http_client(url, filtros, "get");

const getVehiculoGPS = async (id, filtros = {}) =>
  await http_client(`${url}/gps/${id}`, filtros, "get");

const getVehiculo = async (id) => await http_client(`${url}`, { id }, "get");

const storeVehiculo = async (data) => await http_client(url, data, "post");

const updateVehiculo = async (id, data) =>
  await http_client(`${url}/${id}`, data, "put");

const deleteVehiculo = async (id) =>
  await http_client(`${url}/${id}`, {}, "delete");

const getPersonalByVehicle = async (id, params = {}) =>
  await http_client(`${urlPersonalVehicle}/${id}`, params, "get");

const getHistory = async (id = {}) =>
  await http_client(`${urlH}/${id}`, "get");

const getAllHistory = async (request = {}) =>
  await http_client(`${url}/gps/history/complete/${request}`, "get");

const getHistoryGps = async (request = {}) =>
  await http_client(`${url}/gps/history/${request}`, "get");

const savePersonalByVehicle = async (data) =>
  await http_client(`${urlPersonalVehicle}`, data, "post");

const deletePersonalByVehicle = async (idPersona, idVehicle) =>
  await http_client(
    `${urlPersonalVehicle}/${idVehicle}/${idPersona}/delete`,
    {},
    "delete"
  );

  const downloadPlantillaExcel = async (queryParams = {}) => await http_client('/api/v1/download-plantilla/', queryParams, 'get',{},true, "arraybuffer")


export default {
  getVehiculos,
  getVehiculo,
  storeVehiculo,
  updateVehiculo,
  deleteVehiculo,
  getPersonalByVehicle,
  savePersonalByVehicle,
  deletePersonalByVehicle,
  downloadPlantillaExcel,
  getVehiculoGPS,
  getHistory,
  getHistoryGps,
  getAllHistory,
};
