import {http_client} from "@/plugins/http_client";

//Index de usuarios
const getUsuarios= async (params = {}) => await http_client('api/v1/users', params)
const deleteUsuario= async (id) => await http_client(`/api/v1/users/${id}`,{}, 'delete')
//FORM:
const getTipoDocumento= async (params = {}) => await http_client('api/v1/catalogo/tipo-documento', params)
//verificar usuario
const getVerificacionUsuario = async (params) => await http_client(`/api/v1/users/persona/?numero_documento=${params.numero_documento}&id_tipo_documento=${params.id_tipo_documento}`,params, 'post')
const postUsuario = async (body) => await http_client('/api/v1/users', body, 'post')
const putUsuario = async (id, body = {}) => await http_client(`/api/v1/users/${id}`, body, 'put')
const getUsuario= async (id) => await http_client(`/api/v1/users/${id}`,{}, 'get')

export default {
    getUsuarios,
    deleteUsuario,
    getTipoDocumento,
    getVerificacionUsuario,
    postUsuario,
    putUsuario,
    getUsuario,
}