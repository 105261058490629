<template>
  <div>
    <!-- Modal para colocar rango de Fechas -->
    <v-dialog v-model="localOpenModal" max-width="800px" persistent>
      <v-card
        class="rounded-xxl pa-3"
        style="overflow-y: hidden; overflow-x: hidden"
      >
        <!-- <v-card-actions class="justify-end">
            <v-btn icon @click="exitModal(2)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions> -->
        <v-row>
          <v-col cols="12" class="text-h4 text-md-h3 text-center mb-3">
            <span
              style="
                color: #1c1e4d;
                font-family: 'MuseoSans', sans-serif;
                font-weight: bold;
              "
            >
              Rango de fechas
            </span>
          </v-col>
        </v-row>
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" md="3" sm="3">
              <v-text-field
                label="Fecha inicio"
                v-model="datesFront[0]"
                placeholder="DD/MM/YY"
                outlined
                style="border-radius: 10px"
                prepend-inner-icon="mdi-calendar"
                readonly
                dense
                :error-messages="dateErrors('start')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-text-field
                label="Hora inicio"
                @click="handlePicker('inicio')"
                placeholder="00:00"
                v-model="time.startTime"
                outlined
                style="border-radius: 10px"
                prepend-inner-icon="mdi-clock"
                readonly
                dense
                :disabled="datesFront[0] && datesFront[1] ? false : true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-text-field
                label="Fecha fin"
                v-model="datesFront[1]"
                placeholder="DD/MM/YY"
                style="border-radius: 10px"
                outlined
                prepend-inner-icon="mdi-calendar"
                readonly
                dense
                :error-messages="dateErrors('end')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-text-field
                label="Hora fin"
                placeholder="00:00"
                v-model="time.endTime"
                outlined
                prepend-inner-icon="mdi-clock"
                readonly
                style="border-radius: 10px"
                dense
                :disabled="
                  datesFront[1] && datesFront[0] && time.startTime
                    ? false
                    : true
                "
                @click="handlePicker('fin')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- v-date-picker -->
            <v-date-picker
              v-model="dates"
              :min="dates[0] ? dates[0] : ''"
              :max="getFechaActual()"
              range
              full-width
              no-title
              color="blue"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                outlined
                color="#1c1e4d"
                class="ma-2 text-none"
                rounded
                @click="exitModal(3)"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="#1C1E4D"
                rounded
                class="white--text text-none"
                @click="handleSave"
              >
                Guardar fechas
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para seleccionar hora -->
    <v-dialog
      v-model="showPicker"
      max-width="400px"
      class="mx-auto text-center"
    >
      <v-card>
        <v-card-title>Seleccionar hora</v-card-title>
        <v-card-text>
          <v-time-picker
            ref="timePicker"
            v-model="picker"
            color="#1c1e4d"
            full-width
            scrollable
            :min="time.startTime ? time.startTime : ''"
          ></v-time-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="#1c1e4d"
            @click="showPicker = false"
            style="text-transform: capitalize"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="#1c1e4d"
            @click="handleTime"
            style="text-transform: capitalize"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";

export default {
  name: "mostrarRangoFechas",
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localOpenModal: this.openModal,
      showPicker: false,
      dates: ["", ""],
      datesFront: ["", ""],
      time: {
        startTime: "",
        endTime: "",
        asignedTime: null,
      },
      picker: null,
    };
  },
  validations: {
    datesFront: {
      0: { required },
      1: { required },
    },
  },
  watch: {
    openModal(newValue) {
      this.exitModal(1);
      this.localOpenModal = newValue;
    },
    dates(newValue) {
      if (newValue.length > 0) {
        this.time.startTime = "";
        this.time.endTime = "";
        this.datesFront[0] = "";
        this.datesFront[1] = "";
      }
    },
    dateRangeText(newDates) {
      this.datesFront = newDates.map((date) =>
        moment(date).format("DD/MM/YYYY")
      );
    },
  },
  methods: {
    getFechaActual() {
      return moment().format("YYYY-MM-DD"); // Fecha actual
    },
    getFechaMinima() {
      return moment().subtract(1, "years").format("YYYY-MM-DD"); // Un año atrás
    },
    handleTime() {
      if (this.asignedTime === 1) {
        this.time.startTime = this.picker;
        this.time.endTime = "";
      } else if (this.asignedTime === 2) {
        this.time.endTime = this.picker;
      }
      this.picker = "";
      this.showPicker = false;
    },
    handlePicker(field) {
      this.asignedTime = field === "inicio" ? 1 : 2;
      this.showPicker = true;
      if (field === "inicio") {
        this.time.startTime = "";
        this.time.endTime = "";
      }else{
        this.$refs.timePicker.selectingHour = true;
      }
    },
    formatDate(dateString) {
      const momentoFecha = moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD');
      return momentoFecha;
    },
    handleSave() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      if (this.time.startTime === "") {
        this.time.startTime = "00:00";
      }
      if (this.time.endTime === "") {
        this.time.endTime = "23:59";
      }
      const startDateFormatted = this.formatDate(this.dates[0]);
      const endDateFormatted = this.formatDate(this.dates[1]);
      const fechaInicioF = moment(startDateFormatted).format("DD/MM/YYYY");
      const fechaFinF = moment(endDateFormatted).format("DD/MM/YYYY");
      const fecha = {
        fechaInicio: startDateFormatted,
        horaInicio: this.time.startTime,
        fechaFin: endDateFormatted,
        horaFin: this.time.endTime,
        fechaInicioFormatted: fechaInicioF,
        fechaFinFormatted: fechaFinF,
      };
      this.$emit("fechas-seleccionadas", { ...fecha });
      this.exitModal();
    },
    exitModal(refresh) {
      if (refresh === 1) {
        //   this.dates = ['', ''];
        if (this.dates[0] != "" && this.dates[1] != "") {
          this.dates = ["", ""];
        }
        this.datesFront = ["", ""];
        this.time = { startTime: "00:00", endTime: "00:00", asignedTime: null };
      } else if (refresh === 3) {
        this.dates[0] = "";
        this.dates[1] = "";
        this.datesFront[0] = "";
        this.datesFront[1] = "";
        this.time = { startTime: "00:00", endTime: "00:00", asignedTime: null };
        // Limpiar errores
        this.$v.$reset(); // Si estás usando Vuelidate para la validación
        this.$emit("update:openModal");
      } else if (refresh === undefined) {
        this.$emit("selected-data");
      } else {
        this.$emit("update:openModal");
      }
    },
    dateErrors(type) {
      if (!this.$v.datesFront.$anyDirty) return [];
      if (type === "start")
        return !this.$v.datesFront[0].required ? ["Campo requerido"] : [];
      if (type === "end")
        return !this.$v.datesFront[1].required ? ["Campo requerido"] : [];
      return [];
    },
  },
  computed: {
    dateRangeText() {
      return this.dates;
    },
  },
};
</script>
  