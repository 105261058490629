const routerTransporte = [
  {
    path: "/transporte",
    name: "transporte",
    component: () =>
      import(/* webpackChunkName: "transporte" */ "./indexTransporte.vue"),
  },
  {
    path: "/transporte/flota-vehicular/:id?/:op?",
    name: "flota-vehicular",
    props: {
      vehicle: Object,
    },
    component: () =>
      import(
        /* webpackChunkName: "flota-vehicular" */ "./components/formFlotaVehicular.vue"
      ),
  },
];
export default routerTransporte;
