<template>
 <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.91667 33.25C7.04583 33.25 6.30035 32.9399 5.68021 32.3198C5.06007 31.6997 4.75 30.9542 4.75 30.0833V7.91667C4.75 7.04583 5.06007 6.30035 5.68021 5.68021C6.30035 5.06007 7.04583 4.75 7.91667 4.75H30.0833C30.9542 4.75 31.6997 5.06007 32.3198 5.68021C32.9399 6.30035 33.25 7.04583 33.25 7.91667V30.0833C33.25 30.9542 32.9399 31.6997 32.3198 32.3198C31.6997 32.9399 30.9542 33.25 30.0833 33.25H7.91667ZM7.91667 30.0833H30.0833V7.91667H7.91667V30.0833Z" fill="#1C1E4D"/>
<rect x="9.5" y="9.5" width="19" height="19" rx="1.1" fill="#1C1E4D"/>
</svg>
</template>

<script>
export default {
  name: 'checked'
}
</script>

<style scoped>
svg {
  width: 36px;
  height: 36px;
}
</style>
