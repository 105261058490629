const routerRutas = [
    {
      path: "/trayectorias",
      name: "listado-rutas",
      component: () =>
        import(/* webpackChunkName: "listado-rutas" */ "../rutas/views/indexRutas.vue"),
    },
    {
      path: "/trayectoria/configuracion/:id?",
      name: "rutas",
      component: () =>
        import(/* webpackChunkName: "rutas" */ "../rutas/views/indexOptionsRutasComponent.vue"),
    },
    {
      path: "/rutas/recorrido",
      name: "rutas-recorrido",
      component: () =>
        import(/* webpackChunkName: "rutas" */ "../rutas/components/RecorridoRutasTab.vue"),
    },
  ];
export default routerRutas;