<template>
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" fill="none"/>
<circle cx="20" cy="20" r="20" :fill="fill"/>
<path d="M15.16 29V11.96H21.736C24.928 11.96 27.16 14.192 27.16 17.504C27.16 20.816 24.928 23.12 21.736 23.12H18.256V29H15.16ZM18.256 20.432H21.16C22.96 20.432 24.016 19.28 24.016 17.504C24.016 15.752 22.96 14.624 21.208 14.624H18.256V20.432Z" fill="white"/>
</svg>
</template>

<script>
export default {
  name: 'parking',
  props:{
    fill:{
      type: String,
      default: '#96A14C'
    }
  }
}
</script>

<style scoped>
svg {
  width: 36px;
  height: 36px;
}
</style>
