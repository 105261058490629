<template>
  <v-row class="mb-2 mx-2">
    <v-col cols="12" md="3">
      <v-text-field
        v-model="search"
        class="rounded-xl"
        placeholder="Número de denuncia"
        label="Buscar"
        outlined
        color="#1c1e4d"
        dense
         :error-messages="searchErrors"
      ></v-text-field>
    </v-col>

    <v-col cols="12" md="3">
      <v-text-field
        v-model="modelCoordenadas"
        class="rounded-xl"
        placeholder="Latitude, Longitude"
        label="Coordenadas"
        outlined
        color="#1c1e4d"
        dense
        :error-messages="coordinatesErrors"
      ></v-text-field>
    </v-col>

    <v-col cols="12" md="3">
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            class="px-1 rounded-xl"
            label="Fecha"
            prepend-inner-icon="mdi-calendar-outline"
            outlined
            placeholder="DD/MM/YYYY - DD/MM/YYYY"
            persistent-hint
            prepend-icon=""
            v-bind="attrs"
            v-on="on"
            readonly
            color="#1c1e4d"
            dense
            :style="{
              fontSize: $vuetify.breakpoint.smAndDown ? '13px' : '16px',
            }"
            @click="abrirModal = true"
          ></v-text-field>
        </template>
        <mostrarRangoFechas :openModal="abrirModal" @fechas-seleccionadas="actualizarFechaRango" @update:openModal="cerrarRangoFechas" @selected-data="isSelected" /> 
      </v-menu>
    </v-col>
     <!-- Fila para los botones -->
<v-col cols="12" md="3"
:class="
            $vuetify.breakpoint.xsOnly
              ? 'text-center'
              : 'justify-left'
          "       
>
  <v-btn
    rounded
    color="primary"
    class="text-none mr-2"
    dark
    width="100"
    height="40"
    @click="limpiar"
    outlined
  >
    Limpiar
  </v-btn>
  <v-btn
    rounded
    color="primary"
    class="text-none"
    dark
    width="100"
    height="40"
    @click="emitData"
  >
    Filtrar
  </v-btn>
</v-col>

  </v-row>
</template>


<script>
import mostrarRangoFechas from "../views/denuncias/components/mostrarRangoFechas.vue";

export default {
  name: "AppSearchForm",
  components: { mostrarRangoFechas },
  data() {
    return {
      search: "",
      modelCoordenadas: "",
      menu1: false,
      abrirModal: false,
      dateFormatted: [],
      date: "",
    };
  },
  methods: {
    isSelected() {
      this.abrirModal = false;
    },
    // Método para validar coordenadas
    validarCoordenadas(value) {
      const regex = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
      return regex.test(value) || "Formato incorrecto. Ej: -34.61, -58.38";
    },

    // Emite los datos al componente padre
    emitData() {
      const error = this.hasError
      
      if (error) return 
        this.$emit("filter-data", {
          search: this.search,
          coordinates: this.modelCoordenadas,
          dateRange: this.dateFormatted,
        });
      
    },

    // Actualiza el rango de fechas desde el componente hijo
    actualizarFechaRango(fecha) {
      const dataFecha = fecha
      this.dateFormatted = dataFecha;
      const fechaInicio = dataFecha.fechaInicioFormatted;
      const fechaFin = dataFecha.fechaFinFormatted;
      this.date = `${fechaInicio} - ${fechaFin} `;
    },

    // Cierra el modal de rango de fechas
    cerrarRangoFechas() {
      this.abrirModal = false;
      this.date = [];
    },

    // Limpia los campos del formulario
    limpiar() {
      this.search = "";
      this.modelCoordenadas = "";
      this.dateFormatted = [];
      this.date = [];
      this.emitData();
    },
  },
  computed: {
    searchErrors() {
      let errors = [];
    if (this.search.trim()) {
      if (!/^[a-zA-Z0-9\s]*$/.test(this.search)) {
        errors.push("Solo se permiten letras y números.");
      }
    }
    return errors;
    },
    coordinatesErrors() {
      let errors = [];
    if (this.modelCoordenadas.trim()) {
      if (!/^\s*-?\d*\.?\d+\s*,\s*-?\d*\.?\d+\s*$/.test(this.modelCoordenadas)) {
        errors.push("Formato requerido: 45.4521, -34.454");
      }
    }
    return errors;
  },
  hasError() {
      // Verifica si hay algún error en searchErrors o coordinatesErrors
      const searchHasErrors = this.searchErrors.some(() => true);
      const coordinatesHasErrors = this.coordinatesErrors.some(() => true);


      // Retorna true si existe algún error
      return searchHasErrors || coordinatesHasErrors;
    },
  
  },
};
</script>
  
  <style scoped>
.mt-12 {
  margin-top: 12px;
}
</style>
  