import {http_client} from "@/plugins/http_client";
const url = "api/v1/catalogo/get-trayectorias?placa="
//Index y tab generalidades
const getDetailTrayectoria = async(id) => await http_client(`api/v1/trayectoria/paradas/show/${id}`, 'get')
const getRutas= async (filtros = {}) => await http_client('api/v1/trayectoria', filtros)
const getRutasByColonia= async (params = {}) => await http_client('api/v1/catalogo/trayectorias', params)
const getRutasVehiculo= async (id) => await http_client(`${url}${id}`, {}, 'get')
const getRuta = async (id) => await http_client(`/api/v1/trayectoria/${id}`,{}, 'get')
const postRutas = async (body) => await http_client('/api/v1/trayectoria', body, 'post')
const putRutas = async (id, body) => await http_client(`/api/v1/trayectoria/${id}`, body, 'put')
const deleteRuta = async (id) => await http_client(`/api/v1/trayectoria/${id}`,{}, 'delete')
const getAgente= async (params = {}) => await http_client('api/v1/catalogo/agente-generacion', params)
const getDiaSemana= async (params = {}) => await http_client('api/v1/catalogo/dia-semana', params)
const getTipoResiduo= async (params = {}) => await http_client('api/v1/catalogo/tipo-residuo', params)
const getTipoTurno= async  (params = {}) => await http_client('api/v1/catalogo/tipo-turno', params)
//Tab horarios
const postHorarios = async (body) => await http_client('/api/v1/trayectoria/hora', body, 'post')
const getHorarios = async (id) => await http_client(`/api/v1/trayectoria/hora/${id}`,{}, 'get')
const getHorarioDelete = async (id) => await http_client(`/api/v1/trayectoria/hora/${id}`,{}, 'delete')
//municipios /  departamentos / distritos / colonias
const getDepartamentos = async (filtros) => await http_client('/api/v1/catalogo/departamentos')
const getMunicipios = async (filtros = {}) => await http_client(`/api/v1/catalogo/municipios`, filtros, "get")
const getDistritos = async (filtros = {}) => await http_client(`/api/v1/catalogo/distritos`, filtros, "get")
const getColonias = async (filtros = {}) => await http_client(`/api/v1/catalogo/colonias`, filtros, "get")
//tab para vehículos
const getVehiculos = async (id, params = {}) => await http_client(`/api/v1/trayectoria/vehiculo/${id}`,params, 'get')
const getVehiculo = async (filtros = {}) => await http_client(`/api/v1/vehiculo`,filtros, 'get')
const postVehiculos = async (body) => await http_client('/api/v1/trayectoria/vehiculo', body, 'post')
const deleteVehiculo = async (id, params = {}) => await http_client(`/api/v1/trayectoria/vehiculo/${id}?id_horario=${params.id_horario}`, {},'delete')
const detalleVehiculo = async (id) => await http_client(`/api/v1/flota-vehicular/detalle/${id}`,{}, 'get')
// Save colonia a ruta
const postColonia = async (body) => await http_client('/api/v1/trayectoria/colonia', body, 'post')
const deleteColonia = async (id) => await http_client(`/api/v1/trayectoria/colonia/${id}`,{}, 'delete')
const getColonia = async (id) => await http_client(`/api/v1/trayectoria/colonia/${id}`,{}, 'get')

const cargarPlantillaExcel = async (body) => await http_client('/api/v1/upload-rutas/', body, 'post', {'Content-Type': 'multipart/form-data',} )
//Tab Recorrido
const postCargarMapa = async (id, body) => await http_client(`/api/v1/trayectoria/recorrido/${id}`, body, 'post')
const getTrayectoria = async (id, filtros = {}) => await http_client(`/api/v1/trayectoria/recorrido/${id}`, filtros, 'get')
const deleteTrayectoria = async (id) => await http_client(`/api/v1/trayectoria/recorrido/${id}`,{}, 'delete')
const activarTrayectoria = async (id, body = {}) => await http_client(`/api/v1/trayectoria/recorrido/activar/${id}`, body, 'put')
const getTipoParada = async (filtros = {}) => await http_client(`/api/v1/catalogo/tipo-parada`, filtros, 'get')
const getParadas = async (id_trayectoria, id_recorrido, filtros = {}) => await http_client(`/api/v1/trayectoria/paradas/${id_trayectoria}/${id_recorrido}`, filtros, 'get')
const postParadas = async (body) => await http_client(`/api/v1/trayectoria/paradas`, body, 'post')
const deleteParadas = async (id) => await http_client(`/api/v1/trayectoria/paradas/${id}`, {},'delete')

export default {
    getRutas,
    getRuta,
    postRutas,
    putRutas,
    deleteRuta,
    getAgente,
    getDiaSemana,
    getTipoTurno,
    getTipoResiduo,
    postHorarios,
    getHorarios,
    getHorarioDelete,
    getDepartamentos,
    getMunicipios,
    getDistritos,
    getColonias,
    getVehiculos,
    getVehiculo,
    postVehiculos,
    deleteVehiculo,
    detalleVehiculo,
    postColonia,
    deleteColonia,
    getColonia,
    getRutasByColonia,
    cargarPlantillaExcel,
    postCargarMapa,
    getTrayectoria,
    deleteTrayectoria,
    activarTrayectoria,
    getTipoParada,
    getParadas,
    postParadas,
    deleteParadas,
    getDetailTrayectoria,
    getRutasVehiculo,
}