import pathServices from "../../services/path.services"
import router from "../../router";

export default {
    namespaced: true,
    state: {
        alert: {
            show: false,
            type: "info",
            message: "",
            timeout: 2500,
        },
        loader: false,
        sideBar: false,
        menu: [],
        rutas: [],
        redirection: "",
    },
    getters: {
        getAlert: (state) => {
            return state.alert;
        },
    },
    mutations: {
        setAlert: (state, payload) => {
            state.alert = payload;
        },
        setLoader: (state, payload) => {
            state.loader = payload;
        },
        setSideBar: (state, payload) => {
            state.sideBar = payload;
        },
        setMenu: (state, menu) => {
            state.menu = menu;
        },
        setRutas: (state, rutas) => {
            state.rutas = rutas;
        },
        setRedirection: (state, redirection) => {
            state.redirection = redirection
        }
    },
    actions: {
        getMenu: async ({ state, commit }) => {
            try {
              // Reiniciar el estado de redirección antes de obtener el menú
              commit("setRedirection", '');
          
              const response = await pathServices.getAuthorizedPaths();
              const menu = getNavItem(response?.data);
          
              // Buscar si hay un dashboard y redirigir si se encuentra
              const objetoConDashboard = menu.find(objeto => objeto.nombre_uri === "dashboard");
              if (objetoConDashboard) {
                commit("setRedirection", "dashboard");
              } else {
                let redirectionFound = false;
          
                // Iterar sobre el menú para encontrar un child válido para redirección
                for (const item of menu) {
                  if (item.childrens && item.childrens.length > 0) {
                    const validChild = item.childrens.find(child => child.mostrar && child.nombre_uri !== '');
          
                    if (validChild) {
                      commit("setRedirection", validChild.nombre_uri);
                      redirectionFound = true;
                      break; // Detener la búsqueda una vez que se encuentra un validChild
                    }
                  }
                }
          
                // Si no se encuentra ninguna redirección válida, se puede manejar de alguna manera (por ejemplo, mostrar un mensaje de error)
                if (!redirectionFound) {
                  //console.log('No se encontró ninguna redirección válida.');
                }
              }
          
              // Finalmente, establecer el menú y las rutas en el estado
              commit("setMenu", menu);
              commit("setRutas", response.data);
            } catch (error) {
              console.error("Error al obtener el menú:", error);
              // Manejo del error, como mostrar un mensaje al usuario
            }
          },          
        clearMenu: ({commit}) => {
            commit("setMenu", []);
            commit("setRutas", []);
        }
    },
};

const getNavItem = (items) => {
    return items.filter((item) => {
        if (!item.mostrar) return false
        if (item.childrens?.length > 0) item.childrens = getNavItem(item.childrens)
        return true
    })
}
