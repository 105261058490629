<template>
  <v-row class=" d-flex mt-4">
    <v-col cols="12" sm="6" md="4">
      <v-text-field class="inputLatitud rounded-xl" :disabled="isEdit"  v-model="form.latitud" label="Latitud*" outlined dense type="number"
        @blur="$v.form.latitud.$touch()" :error-messages="latitudErrors" @input="latitudInput">
      </v-text-field>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-text-field class="inputLongitud rounded-xl" :disabled="isEdit" v-model="form.longitud" label="Longitud*" outlined dense type="number"
        @blur="$v.form.longitud.$touch()" :error-messages="longitudErrors" @input="longitudInput">
      </v-text-field>
    </v-col>

    <v-col cols="12">
      <div id="mapa" style="width: 100%; height: 250px;">
        <l-map
        ref="markerLocation"
        :zoom="zoom"
        :min-zoom="7"
        :max-zoom="18"
        :center="center"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />
        <l-marker
          :lat-lng="center"
          :icon="customIcon"
          :draggable="!isEdit"
          @dragend="setMarkerPosition"
        />
      </l-map>
       
      </div>
    </v-col>

  </v-row>
</template>

<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";


const isNumeric = function isNumeric(value) {
  return /^-?\d+(\.\d+)?$/.test(value);
}

export default {
  name: 'AppMap',
  props:{
    initialLatitud :{type: Number, default: null},
    initialLongitud :{type: Number, default: null},
    getId : {type: Number, default:null}
  },
  validations() {
    return {
      form: {
        latitud: { required, isNumeric, maxlength: maxLength(20), minLength: minLength(3) },
        longitud: { required, isNumeric, maxlength: maxLength(20), minLength: minLength(3) },
      },
    }
  },
  data: () => ({
    id: null,
    option: null,
    // Mapa
    customIcon: L.icon({
      iconUrl: require('@/assets/img/marker-icon.svg'), // Ruta a tu icono personalizado
        iconSize: [25, 41], // Tamaño del icono
        iconAnchor: [12, 41], // Punto del icono que se corresponde con la posición del marcador
        popupAnchor: [1, -34], // Punto desde el que se abrirá el popup relativo al icono
        shadowSize: [41, 41] // Tamaño de la sombra del icono
      }),
    mapa: null,
    zoom: 15,
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    bounds: null,
    center: [13.686290216243902, -89.23787713050842],
     // Form Map
     form: {
      latitud: null,
      longitud:  null,
    },
    isEdit : false
  }),
  methods: {
    loadMapa() {
      if (!"geolocation" in navigator) {
        this.temporalAlert({
          message: "Tu navegador no soporta el acceso a la ubicación. Intenta con otro",
          type: "error",
        });
        return
      }
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      const success = async (pos) => {
        const crd = pos.coords;
        this.form.latitud = crd.latitude;
        this.form.longitud = crd.longitude;
        this.center = [this.form.latitud, this.form.longitud]
        this.$emit('update', { latitud: this.form.latitud, longitud: this.form.longitud })
      };
      const error = (err) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            this.isActiveAlert = true;
            this.textAlert = "Su ubicación esta bloqueada en este navegador";
            break;
          case error.POSITION_UNAVAILABLE:
            this.isActiveAlert = true;
            this.textAlert = "Su posición no esta disponible";
            break;
          case error.TIMEOUT:
            this.isActiveAlert = true;
            this.textAlert = "Su ha excedido el tiempo de ubicación";
            break;
          case error.UNKNOWN_ERROR:
            this.isActiveAlert = true;
            this.textAlert = "Error desconocido";
            break;
        }
      };
      navigator.geolocation.getCurrentPosition(success, error, options);
    },
    setPos(e) {
      const layer = e.target
      const ll = layer.getLatLng()
      layer.setLatLng(ll)
      return [ll.lat, ll.lng]
    },
    setMarkerPosition(e) {
      this.center = this.setPos(e);
      this.form.latitud = this.center[0]
      this.form.longitud = this.center[1]
      this.$emit('update', { latitud: this.form.latitud, longitud: this.form.longitud })
    },
    latitudInput() {
      // Esta función se ejecutará cada vez que el valor del campo de texto cambie
      this.center[0] = Number(this.form.latitud);
      this.$refs.markerLocation.setLatLng(this.center);
      this.$refs.map.setZoom(this.zoom)
      this.$refs.map.setCenter(this.center)
      this.$emit('update', { latitud: this.form.latitud })

    },
    longitudInput() {
      // Esta función se ejecutará cada vez que el valor del campo de texto cambie
      this.center[1] = Number(this.form.longitud);
      this.$refs.markerLocation.setLatLng(this.center);
      this.$refs.map.setZoom(this.zoom)
      this.$refs.map.setCenter(this.center)
      this.$emit('update', { longitud: this.form.longitud })
    },
    async getUnidadMedida() {
      const { data: unidadMedida } =
        await this.services.unidadMedida.getUnidadMedida({ tipo: 'area' });
      this.items_medidad = unidadMedida;

    },
    async getTipoPropiedadVehiculo() {
      const { data: tipoPropiedadVehiculo } =
        await this.services.tipoPropiedadVehiculo.getTipoPropiedadVehiculo({ tipo: 'zona' });
      this.items_propiedad = tipoPropiedadVehiculo;
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    departamentoReadOnly() {
      if (this.option == 'detail') {
        return true
      } else {
        return false;
      }
    },
    latitudErrors() {
      const errors = [];
      if (!this.$v.form.latitud.$dirty) return errors;
      !this.$v.form.latitud.required && errors.push("La latitud es requerida");
      !this.$v.form.latitud.isNumeric && errors.push("La latitud debe ser numérico");
      !this.$v.form.latitud.maxlength && errors.push("La cantidad máxima de caracteres es 20");
      !this.$v.form.latitud.minLength && errors.push("La cantidad mínima de caracteres es 3");
      return errors;
    },
    longitudErrors() {
      const errors = [];
      if (!this.$v.form.longitud.$dirty) return errors;
      !this.$v.form.longitud.required && errors.push("La longitud es requerida");
      !this.$v.form.longitud.isNumeric && errors.push("La longitud debe ser numérico");
      !this.$v.form.longitud.maxlength && errors.push("La cantidad máxima de caracteres es 20");
      !this.$v.form.longitud.minLength && errors.push("La cantidad mínima de caracteres es 3");
      return errors;
    },
  },
  async mounted() {
    if (this.getId) {
      this.isEdit = true
       this.form.latitud = this.initialLatitud
       this.form.longitud = this.initialLongitud
       this.center = [this.form.latitud, this.form.longitud]
        // Verificar y asignar el centro del mapa
    }
    
    this.id = this.$route.params?.id;
    this.option = this.$route.params?.op;
    await this.getTipoPropiedadVehiculo();
    // await this.getDepartamentos();
    await this.getUnidadMedida();
    if (!this.id) this.loadMapa();
  },
  /*const latitud = this.form.latitud
  const longitud = this.form.longitud
  this.$emit('send-data', {latitud,longitud})*/
  watch: {},
};
</script>

<style scoped>
.inputLatitud>>>input[type="number"] {
  -moz-appearance: textfield;
}

.inputLatitud>>>input::-webkit-outer-spin-button,
.inputLatitud>>>input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.inputLongitud>>>input[type="number"] {
  -moz-appearance: textfield;
}

.inputLongitud>>>input::-webkit-outer-spin-button,
.inputLongitud>>>input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
