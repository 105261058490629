const routerZona = [
  {
    path: "/zona-base",
    name: "zona-base",
    component: () =>
      import(/* webpackChunkName: "zona-base" */ "../zona/indexZona.vue"),
  },
  {
    path: "/zona/configuracion/:op?/:id?",
    name: "zona-configuracion",
    component: () =>
      import(
        /* webpackChunkName: "zona-configuracion" */ "./components/formZonaBase.vue"
      ),
  },
];

export default routerZona;
