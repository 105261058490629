<template>
  <v-dialog v-model="value" :max-width="maxWidth" :overlay-opacity="0.4" persistent class="custom-dialog">
    <v-card class="card">
      <v-card-title v-if="showClose" class="d-flex flex-column justify-center align-center" style="gap: 8px;">
        <!-- Icono y Título -->
        <div v-if="showIconAndTitle" class="d-flex align-center" style="gap: 8px;">
            <span class="btnAzul--text text-center" :style="{ color: '#313945', fontWeight: 'bold',  wordBreak: 'keep-all', fontSize: $vuetify.breakpoint.md ? '25px' : '30px' }">            
              {{ title }}
          </span>
        </div>

        <!-- Subtítulo centrado -->
        <span style="color: #313945; font-weight: bold; word-break: keep-all;" class="btnAzul--text text-center">
          {{ subtitle }}
        </span>

        <!-- Otro texto debajo del subtítulo -->
        <span v-if="showId" class="btnAzul--text text-center" style="font-size: 16px; color: #313945; font-weight: bold;">
            {{ id }}
        </span>
      </v-card-title>

      <v-card-title v-else class="text-h5 primary--text text-center">
        <span class="primary--text">{{ title }}</span>
      </v-card-title>

      <v-card-text class="card-content">
        <v-container fluid class="d-flex flex-column align-center justify-center">
          <v-row>
            <v-col cols="12" class="text-center">
              <slot name="content" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <div class="actions-buttons pb-4" :class="classButtons">
        <slot name="actions" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    closeFlag: {
      type: Boolean,
      default: true,
    },
    title: String,
    subtitle: String,
    id: Number,
    showClose: {
      type: Boolean,
      default: true,
    },
    showIconAndTitle: {
      type: Boolean,
      default: true,
    },
    showId: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [String, Number],
      default: "700",  // Cambiado a String para aceptar px
    },
    classButtons: {
      type: String,
      default: "d-flex justify-end",
    },
    persistent: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style scoped>
.card {
  border-radius: 35px;
  position: relative;
  overflow: hidden;
  z-index: 1000;
}

/* Estilos para el contenido del scroll */
.card-content {
  max-height: 400px; /* Ajusta según sea necesario */
  overflow-y: auto; /* Habilita el scroll vertical */
  position: relative;
}
</style>