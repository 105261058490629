
export default [
    {
        path: "/users",
        name: "users",
        component: () =>
          import(/* webpackChunkName: "usuarios" */ "../usuarios/usuarios.vue"),
      },
      {
          path: "/usuarios/configuracion/:id?",
          name: "usuarios-configuracion",
          props: {
              itemsUsuario: Object,
            },
          component: () =>
          import(/* webpackChunkName: "usuarios-configuracion" */ "../usuarios/components/formUsuarios.vue"),
      },
]