import {http_client} from "@/plugins/http_client";

// const urlExterna = 'https://api.openrouteservice.org/v2/directions/driving-car'
const url = '/api/v1/monitoreo/trackers'


const getMonitoreo = async (params = {}) => await http_client(`${url}`, params, 'get')
const getRutaById = async (id_truck,params = {}) => await http_client(`/api/v1/trayectoria/recorrido/vehiculo/${id_truck}`, params, 'get')
const getPuntosRecoleccionByRoute = async (id_trayectoria, id_recorrido,params = {}) => await http_client(`/api/v1/trayectoria/paradas/${id_trayectoria}/${id_recorrido}`, params, 'get')
const getPlanteles = async (params = {}) => await http_client(`/api/v1/plantel`, params, 'get')
const getPlantelesById = async (id_truck,params = {}) => await http_client(`/api/v1/plantel/${id_truck}`, params, 'get')
const getDenuncias = async (params = {}) => await http_client(`/api/v1/denuncias/list-map`, params, 'get')

export default {
  getMonitoreo,
  getRutaById,
  getPlanteles,
  getPlantelesById,
  getDenuncias,
  getPuntosRecoleccionByRoute
}
