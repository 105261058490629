<template>
  <v-carousel hide-delimiters>
    <v-carousel-item
      v-for="(item,i) in comprobantes"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
  <!-- <v-carousel v-model="model">
    <v-carousel-item
      v-for="(color, i) in colors"
      :key="color"
    >
      <v-sheet
        :color="color"
        height="100%"
        tile
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="text-h2">
            Slide {{ i + 1 }}
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel> -->
</template>

<script>
  export default {
    name: "Carousel",
    props: {
      comprobantes: {
        type: Array,
        default: [],
      },
    },
    data: () => ({

    }),
  }
</script>

<style lang="scss" scoped>

</style>

<style scoped>

</style>