export default [
  {
    path: "gestion-personal",
    name: "gestion-personal",
    component: () =>
      import(
        /* webpackChunkName: "gestionPersonalList" */ "./gestionPersonal.vue"
      ),
  },
  {
    path: "gestion-personal/:op?/:id?",
    name: "personal",
    // props: {
    //   persona: Object,
    // },
    component: () =>
      import(
        /* webpackChunkName: "flota-vehicular" */ "./components/formPersonal.vue"
      ),
  },
];
