import { http_client } from "@/plugins/http_client";

const url = "/api/v1/personas";

const getPersonas = async (params = {}) =>
  await http_client(url, params, "get");

const getPersona = async (id) => await http_client(`${url}/${id}`, {}, "get");

const storePersona = async (data) => await http_client(url, data, "post");

const updatePersona = async (id, data) =>
  await http_client(`${url}/${id}`, data, "put");

const activateDeactivatePersona = async (id) =>
  await http_client(`${url}/${id}`, {}, "delete");

const downloadPlantillaExcel = async (queryParams = {}) => await http_client('/api/v1/download-plantilla/', queryParams, 'get',{},true, "arraybuffer")

const cargarPlantillaExcel = async (body) => await http_client('/api/v1/upload-personas/', body, 'post', {'Content-Type': 'multipart/form-data',} )

const cargarPlantillaFlotaVehicular = async (body) => await http_client('/api/v1/upload-flota-vehicular/', body, 'post', {'Content-Type': 'multipart/form-data',} )


export default {
  getPersonas,
  getPersona,
  storePersona,
  updatePersona,
  activateDeactivatePersona,
  downloadPlantillaExcel,
  cargarPlantillaExcel,
  cargarPlantillaFlotaVehicular,
};
