import {http_client} from "@/plugins/http_client";

const url= "/api/v1/catalogo/unidad-medida?tipo=peso";

const getPesosVehiculo=async(params = {})=>
    await http_client(url,params,"get");


export default {
    getPesosVehiculo,
}