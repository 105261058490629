<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel v-for="(categoria, index) in items" :key="index">
        <v-expansion-panel-header @click="togglePanel(categoria.categoria)">
          <v-row no-gutters>
            <v-col :cols="xsBreakpoint" sm="5">
              <span>{{ categoria.categoria }}</span>
            </v-col>
            <v-col :cols="xsBreakpoint" sm="7" class="text-right mt-3 mb-2">
              <span class="mr-5">
                <a class="mr-2" @click.stop="selecDeselectAll(categoria, true)">Seleccionar todos</a> |
                <a class="ml-2" @click.stop="selecDeselectAll(categoria, false)">Deseleccionar todos</a>
              </span>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mb-0">
            <v-col v-for="(rol, rolIndex) in categoria.roles" :key="rolIndex" :cols="xsBreakpoint" :class="hidenCol(rol.descripcion)">
              <v-checkbox dense v-model="rol.checked" :label="rol.descripcion" @click="select(rol.id)" v-if="rol.descripcion"></v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <span v-if="errorMessages.length > 0" class="red--text">{{ errorMessages[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "AppSearchList",
  props: {
    items: {
    },
    value: {
      type: Array,
      default: () => []
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    expandedPanel: false,
    categoriaSelected: null,
  }),
  methods: {
    select(id) {
      const result = this.value
      const index = result.findIndex(element => element === id)
      if (index >= 0) {
        result.splice(index, 1)
        this.$emit('deleteItem', id)
      } else {
        result.push(id)
        this.$emit('addItem', id)
      }
      this.$emit('input', result)
    },
    selecDeselectAll(categoria, selected) {
      if (this.expandedPanel && this.categoriaSelected == categoria.categoria) {
        let contador=0;
        categoria.roles.forEach(rol => {
          if(rol.checked != selected)contador++;
          rol.checked = selected;
          if (selected)
            this.selectAll(rol.id);
          else
            this.deselectAll(rol.id)
        });
        if(contador<=0){
          this.temporalAlert({
          show: true, type: "warning", message: selected ? "Todos los elementos estan seleccionados" : "No hay elementos seleccionados",
        });
        return;
        }
        this.temporalAlert({
          show: true, type: "success", message: selected ? "Se seleccionaron todos los roles de la categoría" : "Se deseleccionaron todos los roles de la categoría",
        });
      } else {
        this.temporalAlert({
          show: true, type: "error", message: selected ? "Para seleccionar todos los roles primero debes expandir la categoría" : "Para deseleccionar todos los roles primero debes expandir la categoría",
        });
      }

    },
    deselectAll(id) {
      const result = this.value
      const index = result.findIndex(element => element === id)
      if (index >= 0) {
        result.splice(index, 1)
        this.$emit('deleteItem', id)
      }
      this.$emit('input', result)
    }, selectAll(id) {
      const result = this.value
     
      const index = result.findIndex(element => element === id)
     
      if (index < 0) {
        result.push(id)
        this.$emit('addItem', id)
      }
      this.$emit('input', result)
    },
    togglePanel(categoria) {
      if (this.categoriaSelected == null) {
        this.expandedPanel = true;
      } else {
        if (categoria != this.categoriaSelected) {
          this.expandedPanel = true;
        } else {
          this.expandedPanel = !this.expandedPanel;
        }

      }

      this.categoriaSelected = categoria;

    }
  },
  computed: {
    xsBreakpoint() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return "12";
      } else {
        return "6";
      }
    },

    hidenCol() {
      return (paylaod) => {
        return !paylaod ? 'hidenCol' : ''  
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .hidenCol {
    display: none !important;
  }
</style>