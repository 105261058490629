import { http_client } from "@/plugins/http_client";

const url = "/api/v1/plantel";

const getPlanteles = async (params = {}) =>
  await http_client(url, params, "get");

const getPlantelById = async (id) =>
  await http_client(`${url}/${id}`, {}, "get");

const storePlantel = async (data) => await http_client(url, data, "post");

const updatePlantel = async (id, data) =>
  await http_client(`${url}/${id}`, data, "put");

const activateDeactivatePlantel = async (id) =>
  await http_client(`${url}/${id}`, {}, "delete");

export default {
  getPlanteles,
  getPlantelById,
  storePlantel,
  updatePlantel,
  activateDeactivatePlantel,
};
