import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogo/tipo-vehiculo";

const getTiposVehiculo = async (filtro = {}) =>
  await http_client(url, filtro, "get");

export default {
  getTiposVehiculo,
};
