const routerPesaje = [
    {
      path: "/pesaje",
      name: "pesaje",
      component: () =>
        import(/* webpackChunkName: "pesaje" */ "./pesaje.vue"),
    },
    {
      path: "/pesaje/:op/:id?",
      name: "form-pesaje",
      component: () =>
        import(/* webpackChunkName: "pesaje" */ "./components/formPesaje.vue"),
    },
  ];
  export default routerPesaje;