import Vue from "vue";
import auth from "./auth.services";
import users from "./user.services";
import roles from "./roles.services";
import profiles from "./profiles.services";
import typeRoles from "./typeRoles.services";
import paths from "./path.services";
import rutas from "./rutas.services";
import tipoDocumentos from "./tipoDocumentos.services";
import tipoLicencias from "./tipoLicencias.services";
import gestionPersonal from "./gestionPersonal.services";
import flotaVehicular from "./flotaVehicular.services";
import tipoVehiculo from "./tipoVehiculo.services";
import estadoVehiculo from "./estadoVehiculo.services";
import tipoPersonal from "./tipoPersonal.services"
import tipoPropiedadVehiculo from "./tipoPropiedadVehiculo.services";
import usuarios from "./usuarios.services";
import denuncia from "./denuncia.services";
import departamentos from "./departamentos.services";
import unidadMedida from "./unidadMedida.services";
import municipios from "./municipios.services";
import distritos from "./distritos.services";
import planteles from "./zona.services";
import pesaje from "./pesaje.services";
import map from './map.service'
import colonias from "./colonias.services";
import gondolaVehiculo from "./gondolaVehiculo.services";
import pesoVehiculo from "./pesoVehiculo.services";
import capacidadVolumetrica from "./capacidadVolumetrica.services"
import tipoMatricula from './tipoMatricula.services'
import comprobantes from "./comprobantes.services";
import graficas from "./graficas.services";
import comprobanteurl from "./comprobanteurl.services"
import tipoPuntoInteres from "./tipoPuntoInteres.services";

Vue.prototype.services = {
  auth,
  users,
  roles,
  profiles,
  typeRoles,
  paths,
  tipoDocumentos,
  tipoLicencias,
  gestionPersonal,
  flotaVehicular,
  tipoVehiculo,
  estadoVehiculo,
  tipoPersonal,
  tipoPropiedadVehiculo,
  rutas,
  usuarios,
  denuncia,
  departamentos,
  unidadMedida,
  municipios,
  distritos,
  planteles,
  pesaje,
  map,
  colonias,
  gondolaVehiculo,
  pesoVehiculo,
  capacidadVolumetrica,
  tipoMatricula,
  comprobantes,
  comprobanteurl,
  graficas,
  tipoPuntoInteres,
};
