<template>
  <v-app-bar app :elevation="0" color="bgWhite" dense style="height: 100px; background-color: white; width: 100%;">
    <v-app-bar-nav-icon
      v-if="breakpoint"
      @click.stop="setSideBar(!sideBar)"
    ></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-list-item v-if="!breakpoint" two-line class="mt-6" style="display: absolute; justify-content: center; align-items: center; width: 100%; margin-left:25%;  background-color: white;">
        <!-- Imágenes según el logueado -->
        <v-img
          class="avatar"
          src="@/assets/img/LOGO-ANDRES-01.png"
          max-width="200"
          contain
          style="object-fit: contain; max-width: 200px;"
        />
        <v-img
          class="avatar"
          src="@/assets/img/LOGOTIPO.png"
          max-width="200"
          contain
          style="object-fit: contain; max-width: 200px;"
        />
    </v-list-item>

    <div class=" d-flex align-center mt-10">
      <v-menu offset-y transition="scroll-y-transition" rounded="lg">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" elevation="" text small class="mr-3">
            <v-icon left>mdi-menu-down-outline</v-icon>
            <p class="mr-5 mt-4 font-weight-bold primary--text">
              {{ userInfo?.user?.email }}
            </p>
          </v-btn>
        </template>
        <v-list dense nav>
          <v-list-item @click="cerrarSession()" class="my-0">
            <v-list-item-icon>
              <v-icon size="20">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="circular-container" v-show="breakpointCircle">
        <v-icon size="50" color="#ffff">mdi-account-outline</v-icon>
      </div>
    </div>

    <!-- <v-menu offset-y transition="scroll-y-transition" rounded="lg">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" elevation="" small>
          {{ userInfo.user?.email }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list dense nav>
        <v-list-item :to="{ name: 'profile' }" class="my-0">
          <v-list-item-icon>
            <v-icon size="20">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Perfil</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'security' }" class="my-0">
          <v-list-item-icon>
            <v-icon size="20">mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Seguridad</v-list-item-title>
        </v-list-item>
        <v-list-item @click="cerrarSession()" class="my-0">
          <v-list-item-icon>
            <v-icon size="20">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cerrar Sesión</v-list-item-title>
        </v-list-item>
        <v-list-item class="my-0">
          <v-list-item-icon>
            <v-switch
              v-model="$vuetify.theme.dark"
              class="my-0"
              hint="This toggles the global state of the Vuetify theme"
              dense
            ></v-switch>
          </v-list-item-icon>
          <v-list-item-title>Tema Oscuro</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "AppHeader",
  methods: {
    ...mapMutations("utils", ["setSideBar", "setMenu"]),
    ...mapMutations(["setToken"]),
    async cerrarSession() {
      this.setMenu([]);
      this.setToken(null);
      // await this.services.auth.logout()
      localStorage.clear();
      // this.$socket.close()
      // this.sockets.unsubscribe('ubicacion-transporte-colector')
      this.$router.push({ name: "login" }).catch((e) => {});
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("utils", {
      sideBar: "sideBar",
    }),
    breakpoint() {
      if (this.$vuetify.breakpoint.sm) {
        return true;
      } else if (this.$vuetify.breakpoint.xs) {
        return true;
      } else if (this.$vuetify.breakpoint.md) {
        return true;
      } else if (this.$vuetify.breakpoint.lg) {
        return false;
      } else if (this.$vuetify.breakpoint.xl) {
        return false;
      } else {
        return false;
      }
    },
    breakpointCircle() {
      if (this.$vuetify.breakpoint.sm) {
        return false;
      } else if (this.$vuetify.breakpoint.xs) {
        return false;
      } else if (this.$vuetify.breakpoint.md) {
        return false;
      } else if (this.$vuetify.breakpoint.lg) {
        return true;
      } else if (this.$vuetify.breakpoint.xl) {
        return true;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.circular-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1c1e4d;
  /* Fondo azul */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.circular-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.center-images {
}
</style>
