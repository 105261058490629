<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.12342 9.09091H9.84293V2.72727C9.84293 2.4697 9.76054 2.25379 9.59575 2.07955C9.43096 1.9053 9.22677 1.81818 8.98318 1.81818C8.73958 1.81818 8.53539 1.9053 8.3706 2.07955C8.20581 2.25379 8.12342 2.4697 8.12342 2.72727V9.09091ZM2.96489 12.7273H15.0015V10.9091H2.96489V12.7273ZM1.71825 18.1818H3.82465V16.3636C3.82465 16.1061 3.90704 15.8902 4.07183 15.7159C4.23661 15.5417 4.4408 15.4545 4.6844 15.4545C4.928 15.4545 5.13219 15.5417 5.29698 15.7159C5.46176 15.8902 5.54416 16.1061 5.54416 16.3636V18.1818H8.12342V16.3636C8.12342 16.1061 8.20581 15.8902 8.3706 15.7159C8.53539 15.5417 8.73958 15.4545 8.98318 15.4545C9.22677 15.4545 9.43096 15.5417 9.59575 15.7159C9.76054 15.8902 9.84293 16.1061 9.84293 16.3636V18.1818H12.4222V16.3636C12.4222 16.1061 12.5046 15.8902 12.6694 15.7159C12.8342 15.5417 13.0384 15.4545 13.282 15.4545C13.5255 15.4545 13.7297 15.5417 13.8945 15.7159C14.0593 15.8902 14.1417 16.1061 14.1417 16.3636V18.1818H16.2481L15.3884 14.5455H2.578L1.71825 18.1818ZM16.2481 20H1.71825C1.1594 20 0.708034 19.7652 0.364132 19.2955C0.0202295 18.8258 -0.0800752 18.303 0.0632173 17.7273L1.24538 12.7273V10.9091C1.24538 10.4091 1.41375 9.98106 1.75049 9.625C2.08722 9.26894 2.49203 9.09091 2.96489 9.09091H6.40391V2.72727C6.40391 1.9697 6.65467 1.32576 7.1562 0.795455C7.65772 0.265152 8.26671 0 8.98318 0C9.69964 0 10.3086 0.265152 10.8102 0.795455C11.3117 1.32576 11.5624 1.9697 11.5624 2.72727V9.09091H15.0015C15.4743 9.09091 15.8791 9.26894 16.2159 9.625C16.5526 9.98106 16.721 10.4091 16.721 10.9091V12.7273L17.9031 17.7273C18.0894 18.303 18.007 18.8258 17.656 19.2955C17.3049 19.7652 16.8356 20 16.2481 20Z" fill="black"/>
  </svg>
</template>
<script>
export default {
  name: 'CleanIcon'
}
</script>
