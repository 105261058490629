const routerDenuncias = [
  {
    path: "/denuncias",
    name: "denuncias",
    component: () =>
      import(/* webpackChunkName: "Denuncias" */ "./denuncias.vue"),
  },
  {
    path: "/denuncia/:id",
    name: "detalle-denuncia",
    component: () =>
      import(/* webpackChunkName: "Denuncias" */ "./denuncia.vue"),
  },
];
export default routerDenuncias;