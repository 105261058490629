import { http_client } from "@/plugins/http_client";

const url = "/api/v1/catalogo/tipo-documento";

const getTipoDocumentos = async (params = {}) =>
  await http_client(url, params, "get");

export default {
  getTipoDocumentos,
};
