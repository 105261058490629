import { get } from "lodash";
import {http_client} from "../plugins/http_client";


const getGraficaVehiculoTotal = async (filtros = {}) => await http_client('/api/v1/vehiculo/show-grafica',filtros, 'get')

const getGraficaPesaje= async (queryParams={}) => await http_client('/api/v1/pesaje/get-estadisticas-pesaje',queryParams, 'get')

const getGraficaHorario= async (queryParams={}) => await http_client('/api/v1/trayectoria/show-grafica',queryParams, 'get')

const getHorasByVehiculo = async (queryParams={}) => await http_client('/api/v1/vehiculo/get-horas-by-vehiculo',queryParams, 'get')

const getGraficaVehiculoPorPlaca= async (queryParams={}) => await http_client('/api/v1/vehiculo/get-trayectorias-finalizadas-turnos-v1',queryParams, 'get')

const getCantidadVisitas= async (queryParams) => await http_client('/api/v1/vehiculo/get-vehiculos-punto-interes', queryParams, 'get')

const getHorasByPlantel= async (queryParams) => await http_client('/api/v1/pesaje/plantel/tiempo', queryParams, 'get')

const getKmByPlantel= async (queryParams) => await http_client('/api/v1/pesaje/plantel/km', queryParams, 'get')

const getPesajeHorasRecoleccion= async (queryParams) => await http_client('/api/v1/pesaje/recoleccion/horas', queryParams, 'get')

const getRecoleccion= async (queryParams) => await http_client('/api/v1/vehiculo/show-grafica-recoleccion', queryParams, 'get')


export default {
    getGraficaVehiculoTotal,
    getGraficaPesaje,
    getGraficaHorario,
    getGraficaVehiculoPorPlaca,
    getCantidadVisitas,
    getGraficaVehiculoPorPlaca,
    getHorasByVehiculo,
    getPesajeHorasRecoleccion,
    getHorasByPlantel,
    getKmByPlantel,
    getRecoleccion
}