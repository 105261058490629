<template>
    <v-row>
        <v-col cols="12" :class="$vuetify.breakpoint.xsOnly ? 'text-center' : 'd-flex justify-center'">
            <v-btn @click="handleBack" rounded color="primary" class="text-none" outlined>
                Volver
            </v-btn>
            <v-btn rounded v-if="showNextButton" color="primary" class="text-none ml-4" dark @click="handleNext">
                Siguiente
            </v-btn>
        </v-col>
    </v-row>
</template>
  
<script>
export default {
    props: {
        onBackClick: Function,
        onNextClick: Function, 
        showNextButton:  {
            type: Boolean,
            default: true, 
        },
    },
    methods: {
        handleBack() {
            if (this.onBackClick) {
                this.onBackClick();
            }
        },
        handleNext() {
            if (this.onNextClick) {
                this.onNextClick();
            }
        },
    },
};
</script>
  