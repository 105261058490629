<template>
  <v-row class="mt-2">
    <v-col cols="12">
      <app-loader v-if="loader"></app-loader>
      <div class="floating-menu responsiveVehiculosDashboard">
        <div position="topleft">
          <v-row class="justify-start">
            <!-- Botón para mostrar menú de trucks -->
            <v-col v-if="showTrucks" cols="auto" class="d-inline-flex">
              <v-btn class="btnAzul" color="white" icon @click="showMenu">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"
                      >mdi-car-search-outline</v-icon
                    >
                  </template>
                  <span>Vehiculos</span>
                </v-tooltip>
              </v-btn>
            </v-col>

            <!-- Botón para mostrar menú de plantelsantels -->
            <v-col v-if="showPlantelsantels" cols="auto" class="d-inline-flex">
              <v-btn
                class="btnAzul"
                color="white"
                icon
                @click="showMenuInteres"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"
                      >mdi-map-search-outline</v-icon
                    >
                  </template>
                  <span>Puntos de interes</span>
                </v-tooltip>
              </v-btn>
            </v-col>

            <!-- Botón para Cluster -->
            <v-col cols="auto" v-if="showTrucks" style="margin-left: 15px">
              <span
                v-if="$vuetify.breakpoint.mdAndUp"
                class="font-weight-bold"
                style="margin-right: 5px"
                >Ver agrupados</span
              >
              <v-icon @click="isCluster = !isCluster">{{
                isCluster
                  ? "$vuetify.icons.toogle_on"
                  : "$vuetify.icons.toogle_off"
              }}</v-icon>
            </v-col>
          </v-row>
          <div
            v-if="filterMenu && showTrucks"
            class="white elevation-5 rounded"
            ref="menuRef"
          >
            <slot name="menu" ref="menuRef2"></slot>
          </div>
          <div>
            <div
              v-if="filterMenuInteres && showPlantelsantels"
              class="white elevation-5 rounded"
              ref="menuInteresRef"
            >
              <slot
                name="menuPlantels"
                ref="menuInteresRef2"
                :navigate-to-location="navigateToLocation"
              ></slot>
            </div>
          </div>
        </div>
      </div>
      <l-map
        id="map"
        ref="map"
        :zoom="zoom"
        :min-zoom="7"
        :max-zoom="18"
        :center="center"
        :options="optionsMap"
        style="max-height: 95%"
      >
        <l-tile-layer
          v-for="tileProvider in tileLayer"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"
        ></l-tile-layer>
        />

        <l-control-zoom position="topright"></l-control-zoom>

        <!-- Control para el panel de proximidad -->
        <l-control position="topright">
          <div
            v-if="proximityMarkers.length > 0 && showTrucks"
            class="proximity-control"
          >
            <div class="proximity-panel">
              <v-list>
                <v-list-item-group
                  v-for="marker in proximityMarkers"
                  :key="marker.id"
                >
                  <v-list-item
                    @click="toggleSubMenu(marker.id)"
                    @mouseenter="highlightMarker(marker.id)"
                    @mouseleave="limpiarSeleccion(marker.id)"
                    :class="{ active: openSubMenuId === marker.id }"
                  >
                    <v-list-item-content>
                      {{ marker.placa }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>{{
                        openSubMenuId === marker.id
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      }}</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-expand-transition>
                    <v-list v-if="openSubMenuId === marker.id">
                      <v-list-item @click="mostrarTruck(marker.id)">
                        <v-list-item-title>Ver detalles</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="mostrarTruckRuta(marker.id)">
                        <v-list-item-title>Ver ruta</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-expand-transition>
                </v-list-item-group>
              </v-list>
            </div>
          </div>
        </l-control>
        <!--Fin de  control para el panel de proximidad -->

        <l-control position="bottomright">
          <v-menu
            ref="menu"
            top
            class="elevation-0"
            :close-on-click="true"
            :close-on-content-click="false"
            :offset-y="true"
            nudge-right="0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col cols="12">
                  <v-card
                    v-bind="attrs"
                    v-on="on"
                    width="300"
                    height="30"
                    class="d-flex justify-space-between align-center px-2"
                  >
                    <span
                      class="font-weight-bold"
                      style="word-break: break-word; font-size: 15px"
                    >
                      <v-icon color="#000000" class="mb-1"
                        >mdi-chevron-up</v-icon
                      >
                      {{ "Capas" }}
                    </span>
                    <v-icon color="#000000">mdi-layers-outline</v-icon>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <v-list
              color="transparent"
              style="margin-left: 25px"
              class="elevation-0 style--list"
            >
              <div v-for="capa in capas" :key="capa.id">
                <v-card
                  v-if="capa.show"
                  width="300"
                  height="30"
                  class="d-flex justify-space-between align-center px-2"
                  style="cursor: pointer"
                  color="white"
                  @click="showLayerInfo(capa)"
                >
                  <span
                    class="font-weight-bold"
                    style="word-break: break-word; font-size: 15px"
                  >
                    <v-icon
                      :color="capa.value ? '#1C1E4D' : '#000000'"
                      class="mb-1"
                      >{{
                        capa.value
                          ? "mdi-checkbox-blank"
                          : "mdi-checkbox-blank-outline"
                      }}</v-icon
                    >
                    {{ capa.name }}
                  </span>
                </v-card>
              </div>
            </v-list>
          </v-menu>
        </l-control>

        <l-control position="topright" v-if="emptyGeojson">
          <v-row class="justify-end">
            <v-col cols="12" class="d-flex justify-end">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!emptyGeojson"
                    color="white"
                    class="rounded-lg"
                    @click="cleanRouter"
                  >
                    <v-icon>$vuetify.icons.cleanIcon</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar ruta</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </l-control>

        <div v-for="(routeStyle, index) in getRouteStyles" :key="index">
          <l-geo-json
            v-if="routeStyle.status === 'moving'"
            :geojson="routeStyle.geojson"
            :options-style="routeStyle.style"
          ></l-geo-json>
          <l-marker
            v-if="routeStyle.status === 'stopped'"
            :lat-lng="{
              lat: routeStyle.markerPosition[1],
              lng: routeStyle.markerPosition[0],
            }"
          >
            <l-icon
              :icon-anchor="[20, 20]"
              :icon-size="[20, 20]"
              :pop-up-anchor="[0, 25]"
              scroll-wheel-zoom="center"
            >
              <parking :fill="routeStyle.style.color" />
            </l-icon>
          </l-marker>
          <l-marker
            v-if="routeStyle.status === 'parked'"
            :lat-lng="{
              lat: routeStyle.markerPosition[1],
              lng: routeStyle.markerPosition[0],
            }"
          >
            <l-icon
              :icon-anchor="[20, 20]"
              :icon-size="[20, 20]"
              :pop-up-anchor="[0, 25]"
              scroll-wheel-zoom="center"
            >
              <parked :fill="routeStyle.style.color" />
            </l-icon>
          </l-marker>
        </div>
        <l-geo-json
          v-if="emptyGeojson"
          :geojson="geojsonById"
          :options-style="getStyle"
          ref="geoJsonRoutes"
        ></l-geo-json>
        <div v-if="emptyGeojson && showPuntosRecoleccion">
          <l-marker
            v-for="puntos in puntosRecoleccion"
            :key="puntos.id"
            :ref="`puntosRecoleccion-${puntos.id}`"
            :lat-lng="{ lat: puntos.latitud, lng: puntos.longitud }"
            :icon="iconPuntosRecoleccion"
          >
          </l-marker>
        </div>
        <!--Con cluster-->
        <div v-if="isCluster">
          <l-marker-cluster v-if="showTrucks" :options="clusterOptions">
            <div v-for="truck in trucks" :key="truck.state.source_id">
              <l-slide-marker
                v-if="truck.state.gps && truck.state.gps.location"
                :ref="`driverMarker-${truck.state.source_id}`"
                :id="`${truck.state.source_id}`"
                :lat-lng="truck.state.gps.location"
                :duration="6000"
                :options="{
                  state: truck.state,
                  rotationAngle: truck.state.gps.heading,
                  markerZoomAnimation: false,
                }"
                :icon="getIcon(truck.state)"
                @mouseenter="
                  checkProximity(
                    truck.state.gps.location,
                    truck.state.source_id,
                    truck.placa
                  )
                "
              >
                <l-tooltip v-if="openPopup">
                  <v-icon class="mdi-18px"> mdi-information </v-icon>
                  <span>Placa: </span>
                  <span>{{ truck.placa }}</span>
                </l-tooltip>
              </l-slide-marker>
            </div>
          </l-marker-cluster>
        </div>
        <!--Con cluster-->

        <!--SIn cluster-->
        <div v-if="showTrucks && !isCluster">
          <div v-for="truck in trucks" :key="truck.state.source_id">
            <l-slide-marker
              v-if="truck.state.gps && truck.state.gps.location"
              :ref="`driverMarker-${truck.state.source_id}`"
              :id="`${truck.state.source_id}`"
              :lat-lng="truck.state.gps.location"
              :duration="6000"
              :options="{
                state: truck.state,
                rotationAngle: truck.state.gps.heading,
                markerZoomAnimation: false,
              }"
              :icon="getIcon(truck.state)"
              @mouseenter="
                checkProximity(
                  truck.state.gps.location,
                  truck.state.source_id,
                  truck.placa
                )
              "
            >
            </l-slide-marker>
          </div>
        </div>

        <!--SIn cluster-->
        <div v-if="showDenuncias">
          <l-marker
            v-for="(denuncia, index) in denuncias"
            :key="`DM-${denuncia.id}`"
            :ref="`denunciaMarker-${index}`"
            :lat-lng="denuncia.posicion"
            :icon="getIconDenuncia()"
            @click="mostrarDenuncia(denuncia)"
          >
          </l-marker>
        </div>

        <!-- Marcadores de plantels y círculos de ubicación -->
        <div
          showPlantelsantels
          v-for="(plantel, index) in plantels"
          :key="plantel.id"
        >
          <l-marker
            v-if="showPlantelsantels"
            :ref="`plantelMarker-${index}`"
            :lat-lng="plantel.posicion"
            @click="mostrarPlantel(plantel)"
          >
            <l-icon
              :icon-anchor="[20, 20]"
              :icon-size="[20, 20]"
              :pop-up-anchor="[0, 25]"
              scroll-wheel-zoom="center"
            >
              <v-avatar
                :size="40"
                :color="hexToRgba(plantel.color)"
                :style="{ border: '3px solid ' + plantel.color }"
              >
                <v-icon :size="20" dark>{{ plantel.icono }}</v-icon>
              </v-avatar>
            </l-icon>
          </l-marker>

          <l-circle
            v-if="showPlantelsantels && plantel.area && plantel.perimetro > 0"
            :lat-lng="plantel.posicion"
            :radius="parseFloat(plantel.perimetro)"
            :color="plantel.color"
            :fill-opacity="0.3"
            :stroke="true"
          />
          <l-geo-json
            v-if="showPlantelsantels && isGeoJsonPolygon(plantel.objeto)"
            :geojson="plantel.objeto"
            :color="plantel.color"
            :fill-opacity="0.3"
            :stroke="true"
          />
          <l-circle
            v-if="showPlantelsantels && isGeoJsonCircle(plantel.objeto)"
            :lat-lng="getGeoJsonCircleLatLng(plantel.objeto)"
            :radius="getGeoJsonCircleRadius(plantel.objeto)"
            :color="plantel.color"
            :fill-opacity="0.3"
            :stroke="true"
          />
        </div>
      </l-map>
      <!-- Modal para cargar datos -->
      <AppDialog
        v-model="openDetails"
        :title="$vuetify.breakpoint.xsOnly ? '' : 'Detalle del vehículo'"
        :maxWidth="
          $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
            ? '100%'
            : $vuetify.breakpoint.md
            ? '60%'
            : '45%'
        "
        classButtons="d-flex justify-center"
        @input="LimpiarData()"
      >
        <template #content>
          <!-- Contenido del modal -->
          <v-row v-if="$vuetify.breakpoint.xsOnly">
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="d-flex justify-center icono-cerrarPadding2"
            >
              <h1
                class="mensaje-inicio text-xl-h2 font-weight-bold text-center"
                :class="breakpoint"
              >
                {{ "Detalle del vehículo" }}
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="xsBreakpoint" lg="4" md="4" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Propiedad </v-list-item-subtitle>
                  <v-list-item-title class="font-weight-bold btnAzul--text">
                    {{ flota.tipo_propiedad_vehiculo || "-" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" lg="4" md="4" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Tipo </v-list-item-subtitle>
                  <v-list-item-title class="font-weight-bold btnAzul--text">
                    {{ flota.tipo_vehiculo || "-" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" lg="4" md="4" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Nº de ejes </v-list-item-subtitle>
                  <v-list-item-title class="font-weight-bold btnAzul--text">
                    {{ flota.numero_ejes ? flota.numero_ejes + " ejes" : "-" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" lg="4" md="4" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Marca </v-list-item-subtitle>
                  <v-list-item-title class="font-weight-bold btnAzul--text">
                    {{ flota.marca || "-" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" lg="4" md="4" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Modelo </v-list-item-subtitle>
                  <v-list-item-title class="font-weight-bold btnAzul--text">
                    {{ flota.modelo || "-" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" lg="4" md="4" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Año </v-list-item-subtitle>
                  <v-list-item-title class="font-weight-bold btnAzul--text">
                    {{ flota.annio || "-" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" lg="4" md="4" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Placas </v-list-item-subtitle>
                  <v-list-item-title class="font-weight-bold btnAzul--text">
                    {{ flota.placa || "-" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" lg="4" md="4" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle style="white-space: normal !important">
                    Capacidad vehículo
                  </v-list-item-subtitle>
                  <v-list-item-title
                    class="font-weight-bold btnAzul--text"
                    style="white-space: normal !important"
                  >
                    {{
                      flota.capacidad_tonelaje
                        ? flota.capacidad_tonelaje + " toneladas"
                        : "-"
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" lg="4" md="4" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Rutas </v-list-item-subtitle>
                  <v-list-item-title
                    class="font-weight-bold btnAzul--text"
                    style="white-space: normal !important"
                  >
                    {{
                      flota.rutas_asignadas !== undefined &&
                      flota.rutas_asignadas !== null
                        ? flota.rutas_asignadas === 0
                          ? "0 rutas asignadas"
                          : flota.rutas_asignadas === 1
                          ? flota.rutas_asignadas + " ruta asignada"
                          : flota.rutas_asignadas + " rutas asignadas"
                        : "-"
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12">
              <data-table-component :headers="headers" :items="items">
              </data-table-component>
            </v-col>
          </v-row>
        </template>
      </AppDialog>

      <!-- Modal para cargar datos plantel -->
      <AppDialog
        v-model="openDetailsPlantel"
        :title="
          $vuetify.breakpoint.xsOnly ? '' : 'Detalle del punto de interés'
        "
        :maxWidth="
          $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
            ? '100%'
            : $vuetify.breakpoint.md
            ? '60%'
            : '45%'
        "
        classButtons="d-flex justify-center"
      >
        <template #content>
          <!-- Contenido del modal -->
          <v-row v-if="$vuetify.breakpoint.xsOnly">
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="d-flex justify-center icono-cerrarPadding2"
            >
              <h1
                class="mensaje-inicio text-xl-h2 font-weight-bold text-center"
                :class="breakpoint"
              >
                {{ "Detalle del punto de interés" }}
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="xsBreakpoint" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Tipo punto de interés
                  </v-list-item-subtitle>
                  <v-list-item-title
                    class="font-weight-bold btnAzul--text"
                    style="
                      white-space: normal !important;
                      text-overflow: initial;
                    "
                  >
                    {{ plantel?.punto_interes }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Nombre </v-list-item-subtitle>
                  <v-list-item-title
                    class="font-weight-bold btnAzul--text"
                    style="white-space: normal !important"
                  >
                    {{ plantel?.nombre }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Área </v-list-item-subtitle>
                  <v-list-item-title
                    class="font-weight-bold btnAzul--text"
                    style="white-space: normal !important"
                  >
                    {{
                      plantel?.perimetro
                        ? parseFloat(plantel.perimetro).toFixed(2)
                        : "-"
                    }}
                    {{ plantel?.nombre_unidad_area }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col :cols="xsBreakpoint" sm="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Propiedad </v-list-item-subtitle>
                  <v-list-item-title
                    class="font-weight-bold btnAzul--text"
                    style="white-space: normal !important"
                  >
                    {{ plantel?.nombre_tipo_propiedad }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="12">
                  <h4 class="pl-4 mb-5 pt-4 btnAzul--text">Ubicación</h4>
                </v-col>
                <v-col :cols="xsBreakpoint" lg="4" md="4" sm="4">
                  <v-list-item-subtitle
                    class="pa-4"
                    style="white-space: normal !important"
                  >
                    <v-icon left> mdi-bank </v-icon>
                    {{ plantel?.Distrito?.Municipio?.Departamento?.nombre }}
                  </v-list-item-subtitle>
                </v-col>
                <v-col :cols="xsBreakpoint" lg="4" md="4" sm="4">
                  <v-list-item-subtitle
                    class="pa-4"
                    style="white-space: normal !important"
                  >
                    <v-icon left> mdi-home-city </v-icon>
                    {{ plantel?.Distrito?.Municipio?.nombre }}
                  </v-list-item-subtitle>
                </v-col>
                <v-col :cols="xsBreakpoint" lg="4" md="4" sm="4">
                  <v-list-item-subtitle
                    class="pa-4"
                    style="white-space: normal !important"
                  >
                    <v-icon left> mdi-vector-polyline </v-icon>
                    {{ plantel?.Distrito?.nombre }}
                  </v-list-item-subtitle>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle> Dirección </v-list-item-subtitle>
                  <v-list-item-title
                    class="font-weight-bold btnAzul--text"
                    style="white-space: normal !important"
                  >
                    {{ plantel?.direccion }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </template>
      </AppDialog>

      <!-- Modal para cargar datos denuncias -->
      <AppDialog
        v-model="openDetailsDenuncia"
        :max-width="dialogMaxWidth"
        classButtons="d-flex justify-center"
      >
        <template #content>
          <!-- Contenido del modal -->
          <v-row class="pa-2">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="d-flex justify-center icono-cerrarPadding2"
              >
                <v-row class="d-flex align-items-center justify-center">
                  <v-col
                    class="d-flex align-items-center mb-0 pa-0 pt-0"
                    cols="auto"
                  >
                    <v-icon
                      class="btnAzul--text text-center"
                      size="39px"
                      color="#313945"
                      >mdi-account</v-icon
                    >
                  </v-col>
                  <v-col class="d-flex align-items-center" cols="auto">
                    <span
                      style="
                        color: #313945;
                        font-weight: bold;
                        word-break: keep-all;
                      "
                      class="btnAzul--text text-center text-h6 text-md-h4"
                    >
                      Denuncia de ciudadano
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-col cols="12" class="py-0">
              <span
                style="color: #313945; font-weight: bold; word-break: keep-all"
                class="btnAzul--text text-center text-h6 text-md-h5"
              >
                {{ "Lugar del acontecimiento" }}
              </span>
            </v-col>
            <v-col cols="12" class="py-0">
              <span
                class="mensaje-inicio text-h6 text-md-h6 font-weight-bold btnAzul--text text-center"
              >
                # {{ denuncia?.id }}
              </span>
            </v-col>
          </v-row>
          <!-- <v-row justify="center" align="center">
              <v-col cols="12" sm="12" md="12" class="d-flex justify-center align-center">
                <div class="d-inline-flex" style="white-space: normal !important;">
                  <v-img :src="getValoracionImage(denuncia?.puntuacion_caso)"
                    :max-width="$vuetify.breakpoint.xsOnly ? '30' : '35'"
                    :min-width="$vuetify.breakpoint.xsOnly ? '30' : '35'"
                    :max-height="$vuetify.breakpoint.xsOnly ? '30' : '35'"
                    :min-height="$vuetify.breakpoint.xsOnly ? '30' : '35'"></v-img>
                  <span class="pl-2 font-weight-bold"
                    :style="{ color: infoValoracion(denuncia?.puntuacion_caso).color, fontSize: dinamicFontSize }"> {{
                      infoValoracion(denuncia?.puntuacion_caso).text
                    }}</span>
                </div>
              </v-col>
            </v-row> -->
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-list-item class="py-3">
                <v-list-item-content>
                  <v-list-item-subtitle
                    class="mb-4"
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'text-center'
                        : 'd-flex justify-left'
                    "
                  >
                    <v-icon color="grey" left class="mdi-24px">mdi-bank</v-icon>
                    <span
                      style="font-weight: bold; color: grey; font-size: 15px"
                    >
                      {{
                        denuncia?.ciudad?.municipio?.estado_pais
                          ?.nombre_estado_pais || "---"
                      }}
                    </span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    class="mb-4"
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'text-center'
                        : 'd-flex justify-left'
                    "
                  >
                    <v-icon color="grey" left class="mdi-24px"
                      >mdi-home-city</v-icon
                    >
                    <span
                      style="font-weight: bold; color: grey; font-size: 15px"
                    >
                      {{ denuncia?.ciudad?.municipio?.nombre || "---" }}
                    </span>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    class="mb-0"
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'text-center'
                        : 'd-flex justify-left'
                    "
                  >
                    <v-icon color="grey" left class="mdi-24px"
                      >mdi-vector-polyline</v-icon
                    >
                    <span
                      style="font-weight: bold; color: grey; font-size: 15px"
                    >
                      {{ denuncia?.ciudad?.nombre_ciudad || "---" }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-list-item class="py-0">
                <v-list-item-content>
                  <v-list-item-title
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'text-center'
                        : 'd-flex justify-left'
                    "
                    class="font-weight-bold py-0"
                    style="color: #56516f; font-size: 20px"
                  >
                    <p>Operador</p>
                  </v-list-item-title>

                  <v-list-item-subtitle
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'text-center'
                        : 'd-flex justify-left'
                    "
                  >
                    <v-icon class="align-start mr-3 mb-2"
                      >mdi-account-circle-outline</v-icon
                    >
                    <span
                      style="
                        font-weight: bold;
                        color: grey;
                        font-size: 15px;
                        max-width: 15ch;
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                        white-space: normal;
                      "
                      class="font-weight-bold text-left mb-2"
                    >
                      {{
                        denuncia?.detalle_denuncia?.usuario?.nombres_users ||
                        "---"
                      }}
                    </span>
                    <!-- <span
                      style="font-weight: bold; color: grey; font-size: 15px; max-width: 15ch"
                      class="font-weight-bold"
                    >
                    {{ denuncia?.detalle_denuncia?.usuario?.nombres_users || '---'}}
                    </span> -->
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'text-center'
                        : 'd-flex justify-left'
                    "
                  >
                    <v-icon class="mr-3 mb-2"
                      >mdi-card-account-details-outline</v-icon
                    >
                    <span
                      style="font-weight: bold; color: grey; font-size: 15px"
                      class="font-weight-bold mb-2"
                    >
                      {{
                        denuncia?.detalle_denuncia?.usuario
                          ?.numero_documento_users || "---"
                      }}
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'text-center'
                        : 'd-flex justify-left'
                    "
                  >
                    <v-icon class="mr-3">mdi-email-outline</v-icon>
                    <template>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                            style="
                              font-weight: bold;
                              color: grey;
                              font-size: 15px;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              max-width: 16ch;
                            "
                            class="font-weight-bold"
                          >
                            {{
                              denuncia?.detalle_denuncia?.usuario?.email ||
                              "---"
                            }}
                          </span>
                        </template>
                        <span>{{
                          denuncia?.detalle_denuncia?.usuario?.email || "---"
                        }}</span>
                      </v-tooltip>
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row no-gutters class="">
            <v-col cols="12" order="7" class="py-3">
              <v-textarea
                class="Dirección completa"
                rows="3"
                style="border-radius: 10px"
                outlined
                name="input-7-4"
                label="Dirección completa"
                placeholder="Dirección completa"
                v-model="denuncia.referencia"
                readonly
                hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="12" order="8" class="py-4">
              <v-textarea
                class="static label d-flex justify-center"
                rows="3"
                style="border-radius: 10px"
                outlined
                name="input-7-4"
                label="Contenido de la denuncia"
                placeholder="Contenido de la denuncia"
                v-model="denuncia.comentario_caso"
                readonly
                hide-details
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              order="9"
              class="d-flex flex-wrap pt-4 justify-center"
            >
              <v-row class="d-flex justify-center" no-gutters>
                <v-col
                  v-for="(imagen, index) in denuncia.img_sis"
                  :key="index"
                  :cols="12 / Math.min(denuncia.img_sis.length, 3)"
                  class="d-flex justify-center pa-4"
                  style="max-width: 165px"
                >
                  <v-img
                    :src="imagen.ruta"
                    :lazy-src="imagen.ruta"
                    aspect-ratio="1"
                    class="grey lighten-2 rounded-xl"
                    :style="{ objectFit: 'contain' }"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>

              <v-col cols="12" class="d-flex justify-center mt-5">
                <v-btn
                  rounded
                  outlined
                  color="#1C1E4D"
                  class="text-none mr-2"
                  dark
                  height="40"
                  @click="closeDialog()"
                >
                  Cerrar
                </v-btn>
                <v-btn
                  rounded
                  color="#1C1E4D"
                  class="text-none"
                  dark
                  height="40"
                  @click="verDetalles(denuncia)"
                >
                  Seguimiento
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </template>
      </AppDialog>
    </v-col>
  </v-row>
</template>
<script>
// import L from 'leaflet'
import DataTableComponent from "@/components/DataTableComponent.vue";
import * as turf from "@turf/turf";
import { LMap, LTileLayer, LCircle } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import parked from "@/assets/img/parked.vue";
import parking from "@/assets/img/parking.vue";

export default {
  components: {
    DataTableComponent,
    LMap,
    LTileLayer,
    LCircle,
    parked,
    parking,
  },
  props: {
    trucks: {
      type: Array,
      default: () => [],
    },
    plantels: {
      type: Array,
      default: () => [],
    },
    denuncias: {
      type: Array,
      default: () => [],
    },
    gdeon: {
      type: Object,
      required: false,
    },
    gdeon2: {
      type: Array,
      required: false,
    },
    colorsIcon: {
      type: Object,
      default: () => ({}),
    },
    closeModal: {
      type: Boolean,
      require: false,
    },
  },

  data() {
    return {
      isCluster: true,
      selectedTruck: null,
      truckId: "",
      ruta2: false,
      loader: false,
      id: null,
      // Mapa
      zoom: 9,
      center: [13.794185, -88.89653],
      radius: {
        type: Number,
        default: 0,
      },
      datosProps: {
        type: Array,
        default: [],
      },
      tileLayer: [
        {
          name: "tileLayer 1",
          visible: false,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "Esri Satélite El Salvador",
          visible: false,
          attribution:
            '&copy; <a target="_blank" href="https://www.esri.com/en-us/home">Esri</a>',
          url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          /* attribution:
               '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
           url: 'https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg',
           // url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'*/
        },
        {
          name: "tileLayer 3",
          visible: false,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://mt1.google.com/vt/lyrs=s&hl=en&z={z}&x={x}&y={y}",
        },
        {
          name: "tileLayer 4",
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://mt1.google.com/vt/lyrs=m&hl=en&z={z}&x={x}&y={y}",
        },
      ],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // url: 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      optionsMap: {
        zoomControl: false,
        dragging: !L.Browser.mobile,
        touchZoom: true,
      },
      values: [
        {
          type: "moving",
          value: 0,
        },
        {
          type: "parked",
          value: 0,
        },
        {
          type: "stopped",
          value: 0,
        },
      ],
      geojsonById: {},
      puntosRecoleccion: [],
      routeSelected: {
        id_recorrido: null,
        id_trayectoria: null,
      },
      total: 0,
      colors: ["#105B86", "#BC1212", "#FF6200"],
      showDenuncias: true,
      showPlantelsantels: false,
      showTrucks: false,
      showPuntosRecoleccion: false,
      capas: [
        {
          id: 1,
          name: "Denuncias",
          icon: "mdi-layers-outline",
          value: true,
          show: process.env.VUE_APP_SHOWDENUNCIAS_APPLY === "true",
        },
        //ocultando capas inncesarias
        // {
        //   id: 2,
        //   name: 'Puntos de interés',
        //   icon: 'mdi-layers-outline',
        //   value: false,
        //   show: false,
        // },
        // {
        //   id: 3,
        //   name: 'Puntos de recolección',
        //   icon: 'mdi-layers-outline',
        //   value: false,
        //   show: false,
        // },
        // {
        //   id: 4,
        //   name: 'Camiones recolectores',
        //   icon: 'mdi-layers-outline',
        //   value: false,
        //   show: false,
        // },
        {
          id: 5,
          name: "Capa satelital",
          icon: "mdi-layers-outline",
          value: false,
          show: true,
        },
        {
          id: 6,
          name: "Google satélite",
          icon: "mdi-layers-outline",
          value: false,
          show: true,
        },
      ],
      clusterOptions: {
        disableClusteringAtZoom: 15,
        spiderfyOnMaxZoom: false,
        chunkedLoading: true,
        showCoverageOnHover: false,
        // Create our custom cluster icon replacement with the `iconCreateFunction` api
        // See: https://github.com/Leaflet/Leaflet.markercluster#customising-the-clustered-markers
        iconCreateFunction: (cluster) => {},
      },
      iconTruck: L.icon({
        iconUrl: require("@/assets/img/truck_in_route.svg"),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        popupAnchor: [0, -25],
        scrollWheelZoom: "center",
        className: "trucks-style",
      }),
      iconParking: L.icon({
        iconUrl: require("@/assets/img/Estacionado.svg"),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        popupAnchor: [0, -25],
        scrollWheelZoom: "center",
        className: "trucks-style",
      }),
      iconStopped: L.icon({
        iconUrl: require("@/assets/img/Parado.svg"),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        popupAnchor: [0, -25],
        scrollWheelZoom: "center",
        className: "trucks-style",
      }),
      iconDenuncia: L.icon({
        iconUrl: require("@/assets/img/Group_48658.svg"),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        popupAnchor: [0, -25],
        scrollWheelZoom: "center",
      }),
      iconPuntosRecoleccion: L.icon({
        iconUrl: require("@/assets/img/Punto_de_recolección.svg"),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        popupAnchor: [0, -25],
        scrollWheelZoom: "center",
      }),
      iconSttoped: L.icon({
        iconUrl: require("@/assets/img/Punto_de_recolección.svg"),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        popupAnchor: [0, -25],
        scrollWheelZoom: "center",
      }),
      //Dialogo
      openDetails: false,
      openDetailsPlantel: false,
      openDetailsDenuncia: false,
      filterMenu: false,
      filterMenuInteres: false,
      flota: {
        tipo_propiedad_vehiculo: null,
        tipo_vehiculo: null,
        numero_ejes: null,
        marca: "",
        modelo: "",
        annio: null,
        placa: "",
        capacidad_tonelaje: null,
        rutas: null,
        flotaVehicular: [],
      },
      plantel: null,
      denuncia: {
        referencia: "",
        comentario_caso: "",
      },
      headers: [
        {
          text: "Nombre personal",
          align: "start",
          sortable: false,
          value: "nombre",
          class: "text-start font-weight-bold btnAzul--text",
        },
        {
          text: "Rol",
          align: "start",
          sortable: false,
          value: "rol",
          class: "text-start font-weight-bold btnAzul--text",
        },
      ],
      items: [],
      geoJsonOptions: {
        style: function (feature) {
          return { color: feature.properties.color };
        },
      },
      getPoints: [],
      /*variables para nuevas funciones de mapa
       ** agruoar por porximidad
       ***detectando truck seleccionado
       **** */
      closestDistance: Infinity, // Inicializamos la distancia más cercana como infinito
      closestMarkerId: null, // Inicializamos el ID del marcador más cercano
      proximityThreshold: 200, // Umbral de proximidad en metros
      popupLatLng: null,
      openPopupId: null, // Almacena el ID del popup que está actualmente abierto
      openPopup: false, // Almacena el ID del popup que está actualmente abierto
      popupUpdateThreshold: 0.0001, // Umbral para determinar si se ha movido significativamente
      menu: false,
      truckIdChange: "",
      truckplacaChange: "",
      proximityMarkers: [], // Lista de marcadores cercanos
      openSubMenuId: null, // ID del marcador cuyo sub-menú está abierto
      selectedMarkerId: null,
      iconParkingC: L.icon({
        iconUrl: require("@/assets/img/Estacionado.svg"),
        iconSize: [60, 60],
        iconAnchor: [20, 20],
        popupAnchor: [0, -25],
        scrollWheelZoom: "center",
        className: "trucks-style",
      }),
      iconTruckC: L.icon({
        iconUrl: require("@/assets/img/truck_in_route.svg"),
        iconSize: [60, 60],
        iconAnchor: [20, 20],
        popupAnchor: [0, -25],
        scrollWheelZoom: "center",
        className: "trucks-style",
      }),
      iconStoppedC: L.icon({
        iconUrl: require("@/assets/img/Parado.svg"),
        iconSize: [60, 60],
        iconAnchor: [20, 20],
        popupAnchor: [0, -25],
        scrollWheelZoom: "center",
        className: "trucks-style",
      }),
    };
  },
  methods: {
    closeDialog() {
      this.openDetailsDenuncia = false;
      this.$nextTick(() => {
        const cardContent = document.querySelector(".card-content");
        if (cardContent) {
          cardContent.scrollTo({ top: 0, behavior: "smooth" });
        }
      });
    },
    limpiarSeleccion(id) {
      if (!this.openSubMenuId) {
        this.selectedMarkerId = null;
      }
    },
    highlightMarker(id) {
      if (!this.openSubMenuId) {
        this.selectedMarkerId = id;
      } else {
        this.selectedMarkerId = this.openSubMenuId;
      }
    },
    toggleSubMenu(markerId) {
      this.openSubMenuId = this.openSubMenuId === markerId ? null : markerId;
      if (this.openSubMenuId) {
        this.selectedMarkerId = markerId;
      } else {
        (this.selectedMarkerId = null), (this.openSubMenuId = null);
      }
    },
    checkProximity(markerLocation, markerId, placa) {
      if (
        !markerLocation ||
        typeof markerLocation.lat !== "number" ||
        typeof markerLocation.lng !== "number"
      ) {
        return;
      }

      const locationArray = [markerLocation.lng, markerLocation.lat];
      const mapCenter = this.$refs.map.mapObject.getCenter();
      const currentLocation = [mapCenter.lng, mapCenter.lat];

      const from = turf.point(currentLocation);
      const to = turf.point(locationArray);
      const distance = turf.distance(from, to, { units: "meters" });

      if (distance < this.proximityThreshold) {
        this.openPopup = true;
        if (!this.proximityMarkers.find((marker) => marker.id === markerId)) {
          this.proximityMarkers.push({ id: markerId, placa });
        }
      } else {
        // Eliminar el marcador de la lista de marcadores cercanos
        this.proximityMarkers = this.proximityMarkers.filter(
          (marker) => marker.id !== markerId
        );

        // Si ya no hay marcadores cercanos, restablecer los estados
        if (this.proximityMarkers.length === 0) {
          //this.limpiarSeleccion(); // Llama a la función para limpiar la selección
          this.openSubMenuId = null;
          this.selectedMarkerId = null;
        }
      }
    },
    updateProximityMarkers() {
      this.proximityMarkers = []; // Limpiar la lista antes de actualizar

      this.trucks.forEach((truck) => {
        if (truck.state.gps && truck.state.gps.location) {
          this.checkProximity(
            truck.state.gps.location,
            truck.state.source_id,
            truck.placa
          );
        }
      });
    },
    startProximityCheck() {
      this.proximityInterval = setInterval(() => {
        this.trucks.forEach((truck) => {
          this.checkProximity(truck.state.gps.location, truck.state.source_id);
        });
      }, 1000); // Ajusta el intervalo según tus necesidades
    },

    isGeoJsonCircle(objeto) {
      return (
        objeto &&
        objeto.type === "Feature" &&
        objeto.geometry.type === "Point" &&
        objeto.properties.radius
      );
    },
    getGeoJsonCircleLatLng(objeto) {
      if (this.isGeoJsonCircle(objeto)) {
        return [objeto.geometry.coordinates[1], objeto.geometry.coordinates[0]];
      }
      return null;
    },
    getGeoJsonCircleRadius(objeto) {
      if (this.isGeoJsonCircle(objeto)) {
        return objeto.properties.radius;
      }
      return 0;
    },
    isGeoJsonPolygon(objeto) {
      return (
        objeto &&
        objeto.type === "Feature" &&
        (objeto.geometry.type === "Polygon" ||
          objeto.geometry.type === "LineString")
      );
    },
    // Función para calcular los límites de un conjunto de coordenadas
    calculateBoundingBox(geojson) {
      return geojson ? turf.bbox(geojson) : [0, 0, 0, 0];
    },
    calculateCenter(bbox) {
      return [(bbox[1] + bbox[3]) / 2, (bbox[0] + bbox[2]) / 2];
    },
    adjustCenter(center) {
      // Ajusta el centro moviéndolo a la derecha
      return [center[0], center[1]];
    },
    calculateZoom(map, bbox) {
      const bounds = L.latLngBounds([
        [bbox[1], bbox[0]],
        [bbox[3], bbox[2]],
      ]);
      return map.getBoundsZoom(bounds) - 0.3; // Ajusta el zoom si es necesario
    },
    updateMapView(geojson, shiftAmount = 0.03) {
      if (this.$refs.map && this.$refs.map.mapObject) {
        const map = this.$refs.map.mapObject;
        const bbox = this.calculateBoundingBox(geojson);
        const center = this.calculateCenter(bbox);
        const adjustedCenter = this.adjustCenter(center, shiftAmount);
        const zoom = this.calculateZoom(map, bbox);
        map.setView(adjustedCenter, zoom);
      }
    },
    LimpiarData() {
      this.flota = {
        tipo_propiedad_vehiculo: null,
        tipo_vehiculo: null,
        numero_ejes: null,
        marca: "",
        modelo: "",
        annio: null,
        placa: "",
        capacidad_tonelaje: null,
        rutas: null,
        flotaVehicular: [],
      };
    },
    navigateToLocation(item) {
      this.center = [item.latitud, item.longitud];
      this.zoom = 15; // Ajustar el nivel de zoom según sea necesario
      this.filterMenu = false;
      this.filterMenuInteres = false;

      // Asegurarse de que el mapa se redibuje
      this.$nextTick(() => {
        this.$refs.map.mapObject.setView(this.center, this.zoom);
      });
    },

    verDetalles(denuncia) {
      this.$router.push({
        name: "detalle-denuncia",
        params: { id: denuncia.id },
      });
    },
    async showLayerInfo(layer) {
      if (layer.id === 1) {
        this.$emit("show-denuncias", this.showDenuncias);
        this.$refs.menu.save();
      }
      layer.value = !layer.value;
      switch (layer.id) {
        case 1:
          if (process.env.VUE_APP_SHOWDENUNCIAS_APPLY === "true") {
            if (!this.showDenuncias) {
              this.$parent.showDenuncias();
            }
            this.showDenuncias = !this.showDenuncias;
            if (!this.showDenuncias) {
              this.$parent.cleanIntervalDenuncia();
            }
          }
          break;
        case 2:
          if (!this.showPlantelsantels) {
            await this.$parent.showPlantels();
          }
          this.showPlantelsantels = !this.showPlantelsantels;
          break;
        case 3:
          if (!this.showPuntosRecoleccion) {
            const { data, status } =
              await this.services.map.getPuntosRecoleccionByRoute(
                this.routeSelected.id_trayectoria,
                this.routeSelected.id_recorrido
              );
            if (status === 200) {
              this.puntosRecoleccion = data;
            }
          } else {
            this.puntosRecoleccion = [];
          }
          this.showPuntosRecoleccion = !this.showPuntosRecoleccion;

          break;
        case 4:
          if (!this.showTrucks) {
            await this.$parent.showTrucks();
          } else {
            //this.$parent.closeSocket()
          }
          this.showTrucks = !this.showTrucks;
          break;
        case 5:
          this.tileLayer[1].visible = !this.tileLayer[1].visible;
          if (this.tileLayer[1].visible === true) {
            this.capas[2].value = false;
            this.tileLayer[0].visible = false;
            this.tileLayer[2].visible = false;
            this.tileLayer[3].visible = false;
            // this.capas[5].value = false
          } else {
            this.tileLayer[3].visible = true;
          }
          break;
        case 6:
          this.tileLayer[2].visible = !this.tileLayer[2].visible;
          if (this.tileLayer[2].visible) {
            this.capas[1].value = false;
            this.tileLayer[0].visible = false;
            this.tileLayer[1].visible = false;
            this.tileLayer[3].visible = false;
            // this.capas[4].value = false
          } else {
            this.tileLayer[3].visible = true;
            // this.capas[4].value = false
          }
          break;
      }
    },
    cleanRouter() {
      // this.$emit("show-menu-trucks");
      // this.$emit("reset-socket");
      this.geojsonById = {};
      this.getPoints = [];
      this.ruta2 = false;
      this.puntosRecoleccion = [];
      this.capas.find((capa) => capa.id === 3).show = false;
      let center = this.getLatLng({ lat: 13.794185, lng: -88.89653 });
      this.$refs.map.mapObject.flyTo(center, 9, {
        animate: true,
        pan: {
          duration: 8,
        },
      });
    },
    getLatLng(location) {
      return L.latLng([location.lat, location.lng]);
    },
    setCenterLatLng(location) {
      let center = this.getLatLng(location);
      this.$refs.map.setZoom(15);
      this.$refs.map.setCenter(center);
    },
    setCenterElSalvador() {
      let center = this.getLatLng({ lat: 13.794185, lng: -88.89653 });
      this.$refs.map.setZoom(9);
      this.$refs.map.setCenter(center);
    },
    getStyle() {
      return {
        fillColor: "blue",
        color: "blue",
        weight: 7,
        // dashArray: "5, 10",
      };
    },
    getStyle2() {
      return {
        fillColor: "red",
        color: "black",
        weight: 7,
        // dashArray: "5, 10",
      };
    },
    getIcon(truck) {
      try {
        // Verifica si truck es un objeto con source_id y movement_status
        if (!truck || typeof truck !== "object") {
          return this.iconDefault; // Retorna un icono por defecto si truck no es un objeto
        }

        // Verifica si el camión es el seleccionado
        if (truck.source_id === this.selectedMarkerId) {
          if (truck.movement_status === "moving") {
            return this.iconTruckC;
          } else if (truck.movement_status === "parked") {
            return this.iconParkingC;
          } else if (truck.movement_status === "stopped") {
            return this.iconStoppedC;
          } else {
            return this.iconDefault; // Retorna un icono por defecto si el estado es desconocido
          }
        }

        // Verifica si truck tiene movement_status
        if (!truck.movement_status) {
          return this.iconDefault; // Retorna un icono por defecto si movement_status no está definido
        }

        // Determina el icono basado en el estado del movimiento
        if (truck.movement_status === "moving") {
          return this.iconTruck;
        } else if (truck.movement_status === "parked") {
          return this.iconParking;
        } else if (truck.movement_status === "stopped") {
          return this.iconStopped;
        } else {
          return this.iconDefault; // Retorna un icono por defecto si el estado es desconocido
        }
      } catch (error) {
        return this.iconDefault; // Retorna un icono por defecto en caso de error
      }
    },
    getIconDenuncia() {
      return this.iconDenuncia;
    },
    async mostrarTruck(truck) {
      try {
        let response = await this.services.flotaVehicular.getVehiculoGPS(truck);
        if (response.status === 201 || response.status === 200) {
          this.flota = response?.data;
          if (this.flota.flotaVehicular.length !== 0) {
            this.items = this.flota.flotaVehicular.map((personal) => {
              let nombreCompleto = "";

              if (personal.nombre_persona) {
                nombreCompleto += personal.nombre_persona;
              }

              if (personal.segundo_nombre) {
                nombreCompleto += " " + personal.segundo_nombre;
              }

              if (personal.primer_apellido) {
                nombreCompleto += " " + personal.primer_apellido;
              }

              if (personal.segundo_apellido) {
                nombreCompleto += " " + personal.segundo_apellido;
              }
              return {
                id: personal.id,
                nombre: nombreCompleto,
                rol:
                  personal?.es_motorista == true ? "Motorista" : "Tripulante",
              };
            });
          }
        }
      } catch (e) {
        this.items = this.flota.flotaVehicular.map((personal) => {
          let nombreCompleto = "";

          if (personal.nombre_persona) {
            nombreCompleto += personal.nombre_persona;
          }

          if (personal.segundo_nombre) {
            nombreCompleto += " " + personal.segundo_nombre;
          }

          if (personal.primer_apellido) {
            nombreCompleto += " " + personal.primer_apellido;
          }

          if (personal.segundo_apellido) {
            nombreCompleto += " " + personal.segundo_apellido;
          }
          return {
            id: personal.id,
            nombre: nombreCompleto,
            rol: personal?.es_motorista == true ? "Motorista" : "Tripulante",
          };
        });
      }
      this.openDetails = true;
    },
    async mostrarTruckRuta(truck) {
      try {
        const { data, status } = await this.services.map.getRutaById(truck);
        if (status === 200) {
          this.capas.find((capa) => capa.id === 3).show = true;
          this.routeSelected.id_recorrido = data._id;
          this.routeSelected.id_trayectoria = data.id_trayectoria;
          this.geojsonById = data.geojson.routes[0].geometry;
          this.$nextTick(() => {
            // this.$refs.map.mapObject.fitBounds(this.$refs.geoJsonRoutes.mapObject.getBounds());
            this.$refs.map.mapObject.flyTo(
              this.centerCalculate,
              this.zoomCalculate,
              {
                animate: true,
                pan: {
                  duration: 10,
                },
              }
            );
            this.$refs.map.mapObject.closePopup();
          });
        }
      } catch (e) {
        this.$refs.map.mapObject.closePopup();
        this.geojsonById = {};
        this.temporalAlert({
          show: true,
          message:
            e.response?.data?.err?.description ||
            e.response?.data?.message ||
            "Error al obtener la ruta",
          type: "error",
        });
      }
    },
    limpiarRutaTruck() {
      let geoJson = new L.LayerGroup(this.geojsonById);
      this.$refs.map.removeLayer(geoJson);
    },
    async mostrarPlantel(item) {
      try {
        let response = await this.services.map.getPlantelesById(item.id);
        if (response.status === 201 || response.status === 200) {
          this.plantel = response?.data;
          this.openDetailsPlantel = true;
        }
      } catch (e) {
        const error = e?.response?.data?.err?.description;
        this.temporalAlert({
          show: true,
          message: error ? error : "Error al obtener los detalles",
          type: "error",
        });
      }
    },
    async mostrarDenuncia(denuncia) {
      try {
        let response = await this.services.denuncia.getDetalleDenuncia(
          denuncia.id
        );
        if (response.status === 201 || response.status === 200) {
          this.denuncia = response?.data;

          this.denuncia.img_sis = this.denuncia.img_sis.map((imagen) => {
            imagen.ruta = imagen.ruta.replace("public", "storage");
            return {
              referencia: this.denuncia.detalle_denuncia.direccion,
              comentario_caso: this.denuncia.detalle_denuncia.contenido,
              id: imagen?.id,
              img_sis_atencion_ciudadana_id:
                imagen?.img_sis_atencion_ciudadana_id,
              nombre: imagen?.nombre,
              ruta: `${process.env.VUE_APP_SAC_URL}/${imagen?.ruta}`,
            };
          });
          this.denuncia.referencia = this.denuncia.detalle_denuncia.direccion;
          this.denuncia.comentario_caso = this.denuncia.detalle_denuncia.contenido;
          this.openDetailsDenuncia = true;
        }
      } catch (e) {
        const error = e?.response?.data?.err?.description;
        this.temporalAlert({
          show: true,
          message: error ? error : "Error al obtener los detalles",
          type: "error",
        });
      }
    },
    getValoracionImage(valoracion) {
      switch (valoracion) {
        case 1:
          return require("@/assets/img/Insatisfecho_v2.svg");
        case 2:
          return require("@/assets/img/Satisfecho_v2.svg");
        case 3:
          return require("@/assets/img/Muy_satisfecho_v2.svg");
        default:
          return null;
      }
    },
    infoValoracion(estado) {
      let info = {};
      if (estado === 1) {
        info = {
          color: "#F73A1D",
          text: "Insatisfecho",
        };
      } else if (estado === 2) {
        info = {
          color: "#FCD435",
          text: "Satisfecho",
        };
      } else if (estado === 3) {
        info = {
          color: "#4BC214",
          text: "Muy satisfecho",
        };
      }
      return info;
    },
    showMenu() {
      this.$emit("show-menu-trucks");
      this.filterMenu = !this.filterMenu;
      this.filterMenuInteres = false;
    },
    showMenuInteres() {
      this.filterMenuInteres = !this.filterMenuInteres;
      this.filterMenu = false;
    },
    hexToRgba(hex, alpha = 1) {
      // Eliminar el # al inicio si está presente
      hex = hex.replace(/^#/, "");

      // Separar los componentes de color
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      // Verificar y ajustar el valor de alpha
      const validatedAlpha = alpha >= 0 && alpha <= 1 ? alpha : 1;

      // Devolver el color en formato RGBA
      return `rgba(${r}, ${g}, ${b}, ${validatedAlpha})`;
    },

    //   Creacion de arcos para cluster group //
    /**
     * @description Convierte coordenadas polares a coordenadas cartesianas
     * @params {number} centerx and centery - Coordenadas del centro polar
     * @params {number} radius - Radio de la circunferencia
     * @params {number} angleInDegrees - Ángulo en grados
     * **/
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    },
    /**
     * @description Genera la descripción de un arco
     * @params {number} x and y - Coordenadas del centro del arco
     * @params {number} innerRadius and outerRadius - Radio interno y externo del arco
     * @params {number} startAngle and endAngle - Ángulo de inicio y fin del arco
     * */
    describeArc(x, y, innerRadius, outerRadius, startAngle, endAngle) {
      const startOuter = this.polarToCartesian(x, y, outerRadius, endAngle);
      const endOuter = this.polarToCartesian(x, y, outerRadius, startAngle);
      const startInner = this.polarToCartesian(x, y, innerRadius, endAngle);
      const endInner = this.polarToCartesian(x, y, innerRadius, startAngle);

      const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

      return [
        "M",
        startOuter.x,
        startOuter.y,
        "A",
        outerRadius,
        outerRadius,
        0,
        largeArcFlag,
        0,
        endOuter.x,
        endOuter.y,
        "L",
        endInner.x,
        endInner.y,
        "A",
        innerRadius,
        innerRadius,
        0,
        largeArcFlag,
        1,
        startInner.x,
        startInner.y,
        "Z",
      ].join(" ");
    },
    /**
     * @description Genera la descripción de un círculo completo
     * @params {number} x and y - Coordenadas del centro del círculo
     * @params {number} innerRadius and outerRadius - Radio interno y externo del círculo
     * */
    describeFullCircle(x, y, innerRadius, outerRadius) {
      return [
        "M",
        x,
        y - outerRadius,
        "A",
        outerRadius,
        outerRadius,
        0,
        1,
        0,
        x,
        y + outerRadius,
        "A",
        outerRadius,
        outerRadius,
        0,
        1,
        0,
        x,
        y - outerRadius,
        "M",
        x,
        y - innerRadius,
        "A",
        innerRadius,
        innerRadius,
        0,
        1,
        1,
        x,
        y + innerRadius,
        "A",
        innerRadius,
        innerRadius,
        0,
        1,
        1,
        x,
        y - innerRadius,
        "Z",
      ].join(" ");
    },
    /**
     * @description Calcula la posición de el texto en el arco o círculo
     * @params {number} startAngle and endAngle - Ángulo de inicio y fin del arco
     * @params {number} innerRadius and outerRadius - Radio interno y externo del arco
     * @params {number} centerX and centerY - Coordenadas del centro del arco
     * */
    calculateLabelPosition(
      startAngle,
      endAngle,
      innerRadius,
      outerRadius,
      centerX,
      centerY
    ) {
      const middleAngle = (startAngle + endAngle) / 2;
      const position = this.polarToCartesian(
        centerX,
        centerY,
        (innerRadius + outerRadius) / 2,
        middleAngle
      );
      return position;
    },
    /**
     * @description Crea el estilo de los clusters
     * */
    creacionStyleClusters() {
      this.clusterOptions.iconCreateFunction = (cluster) => {
        this.values = [
          {
            type: "moving",
            value: 0,
          },
          {
            type: "parked",
            value: 0,
          },
          {
            type: "stopped",
            value: 0,
          },
        ];
        const childCount = cluster.getChildCount();
        let c = " my-marker-cluster-";
        if (childCount < 10) {
          c += "small";
        } else if (childCount < 100) {
          c += "medium";
        } else {
          c += "large";
        }

        cluster.getAllChildMarkers().forEach((marker) => {
          if (marker.options.state.movement_status === "moving") {
            if (this.values[0] !== undefined) {
              this.values[0].value += 1;
            }
          } else if (marker.options.state.movement_status === "parked") {
            if (this.values[1] !== undefined) {
              this.values[1].value += 1;
            }
          } else if (marker.options.state.movement_status === "stopped") {
            if (this.values[2] !== undefined) {
              this.values[2].value += 1;
            }
          }
        });
        this.total = this.values.reduce((acc, value) => acc + value.value, 0);
        let html = '<svg class="svg-item" viewBox="0 0 200 200">';
        // const percentages = ((this.values[i] / this.total) * 100).toFixed(2) + '%'
        const centerX = 100;
        const centerY = 100;
        const outerRadius = 100;
        const innerRadius = 50;

        let currentAngle = 0;

        if (this.values.filter((value) => value.value !== 0).length === 1) {
          const newVal = this.values.filter((value) => value.value !== 0);
          const typeMarker = {
            moving: 0,
            parked: 1,
            stopped: 2,
          };
          const sliceAngle = 360;
          const endAngle = currentAngle + sliceAngle;
          const labelPosition = this.calculateLabelPosition(
            currentAngle,
            endAngle,
            innerRadius,
            outerRadius,
            centerX,
            centerY
          );

          html +=
            '  <path style="opacity: 60%"  d="' +
            this.describeFullCircle(
              centerX,
              centerY,
              innerRadius,
              outerRadius
            ) +
            '" fill="' +
            this.colors[typeMarker[newVal[0].type]] +
            '"/>' +
            '  <text  x="' +
            labelPosition.x +
            '" y="' +
            labelPosition.y +
            '" fill="#ffffff" font-size="25" text-anchor="middle" dominant-baseline="middle">' +
            newVal[0].value +
            "</text>";
        } else {
          for (let i = 0; i < this.values.length; i++) {
            if (this.values[i].value !== 0) {
              const typeMarker = {
                moving: 0,
                parked: 1,
                stopped: 2,
              };
              const sliceAngle = (this.values[i].value / this.total) * 360;
              const endAngle = currentAngle + sliceAngle;
              const labelPosition = this.calculateLabelPosition(
                currentAngle,
                endAngle,
                innerRadius,
                outerRadius,
                centerX,
                centerY
              );

              html +=
                '  <path style="opacity: 60%"  d="' +
                this.describeArc(
                  centerX,
                  centerY,
                  innerRadius,
                  outerRadius,
                  currentAngle,
                  endAngle
                ) +
                '" fill="' +
                this.colors[typeMarker[this.values[i].type]] +
                '"/>' +
                '  <text  x="' +
                labelPosition.x +
                '" y="' +
                labelPosition.y +
                '" fill="#ffffff" font-size="25" text-anchor="middle" dominant-baseline="middle">' +
                this.values[i].value +
                "</text>";

              currentAngle = endAngle;
            }
          }
        }

        html += "</svg>";

        // '  <path id="slice2" d="" fill="#031295"/>' +
        // '  <path id="slice3" d="" fill="#9AEBA3"/>' +
        // '  <text id="percent2" x="0" y="0" fill="#ffffff" font-size="10" text-anchor="middle" dominant-baseline="middle"></text>' +
        // '  <text id="percent3" x="0" y="0" fill="#ffffff" font-size="10" text-anchor="middle" dominant-baseline="middle"></text>' +

        return new L.DivIcon({
          html: html,
          className: "style-cluster" + c,
        });
      };
    },
  },
  watch: {
    closeModal(newVal) {
      if (!newVal) {
        this.cleanRouter();
      }
    },
    colorsIcon: {
      handler(newColors) {
        if (newColors && Object.keys(newColors).length > 0) {
        }
      },
      deep: true,
    },
    gdeon: {
      handler(newVal) {
        if (newVal && Object.keys(newVal).length > 0) {
          this.truckId = newVal.placa.placa;
          if (
            newVal.rutas &&
            newVal.rutas.geojson &&
            newVal.rutas.geojson.routes &&
            newVal.rutas.geojson.routes[0] &&
            newVal.rutas.geojson.routes[0].geometry
          ) {
            this.geojsonById = newVal.rutas.geojson.routes[0].geometry;
            this.$nextTick(() => {
              this.updateMapView(this.geojsonById);
            });
          }
        }
      },
      immediate: true,
      deep: true,
    },
    gdeon2: {
      handler(newVal) {
        if (Array.isArray(newVal)) {
          // Actualizar getPoints con todas las rutas
          this.getPoints = newVal
            .map((route) => {
              if (
                route &&
                route.response &&
                Array.isArray(route.response.routes)
              ) {
                return route.response.routes
                  .map((r) => {
                    if (r.geometry && Array.isArray(r.geometry.coordinates)) {
                      const coordinates = r.geometry.coordinates.map(
                        (coordinate) => {
                          if (
                            Array.isArray(coordinate) &&
                            coordinate.length === 2
                          ) {
                            return [coordinate[0], coordinate[1]]; // Invertir lat y lon
                          }
                          return coordinate;
                        }
                      );
                      return {
                        id: route.id, // Agregar el id aquí
                        status: route.status,
                        type: "Feature",
                        geometry: {
                          type: "LineString",
                          coordinates: coordinates,
                        },
                      };
                    }
                    return null;
                  })
                  .filter(Boolean);
              }
              return [];
            })
            .flat();

          // Enviar solo la última geometría a updateMapView
          const lastGeometry = this.getPoints[this.getPoints.length - 1];
          if (lastGeometry) {
            this.$nextTick(() => {
              this.updateMapView(lastGeometry.geometry);
            });
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   const map = this.$refs.map.mapObject;
    //   map.on("moveend zoomend", this.updateProximityMarkers);
    //   this.updateProximityMarkers();
    // });
    // this.startProximityCheck();
    // this.creacionStyleClusters();
    // // Escuchar los eventos emitidos por Dashboard.vue
    // this.$on("navigate-to-location", this.navigateToLocation);
    // // this.$on('zoom-to-location', this.zoomToLocation);
    // const capaDefault = this.capas.find((capa) => capa.id === 4);
    // if (capaDefault) {
    //   this.showLayerInfo(capaDefault);
    // }
  },
  beforeDestroy() {
    clearInterval(this.proximityInterval); // Limpia el intervalo cuando el componente se destruye
  },
  computed: {
    dialogMaxWidth() {
      const breakpoint = this.$vuetify.breakpoint;

      if (breakpoint.sm || breakpoint.xs) {
        return "100%";
      } else if (breakpoint.md) {
        return "80%";
      } else {
        return "40%";
      }
    },
    getRouteStyles() {
      return this.getPoints.map((route) => {
        const id = route.id; // Extraer el id de la ruta
        const color =
          (this.colorsIcon[id] && this.colorsIcon[id].hexa) || "#96A14C"; // Color por defecto si no se encuentra el ID
        return {
          geojson: route,
          style: {
            color: color,
            weight: 5, // Ajusta según tus necesidades
            opacity: 1,
          },
          status: route.status,
          markerPosition: route.geometry.coordinates[0],
        };
      });
    },
    emptyGeojson() {
      if (!this.geojsonById && this.geojsonById?.coordinates?.length > 0) {
        this.cleanRouter();
        return false;
      }
      return this.geojsonById && this.geojsonById?.coordinates?.length > 0;
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    bbox() {
      const geojson = this.geojsonById || this.getPoints;
      return this.calculateBoundingBox(geojson);
    },
    centerCalculate() {
      return this.calculateCenter(this.bbox);
    },
    adjustedCenter() {
      return this.adjustCenter(this.center);
    },
    zoomCalculate() {
      const map = this.$refs.map.mapObject;
      return this.calculateZoom(map, this.bbox);
    },
    breakpoint() {
      if (this.$vuetify.breakpoint.sm) {
        return "text-h5";
      } else if (this.$vuetify.breakpoint.xs) {
        return "text-h6";
      } else if (this.$vuetify.breakpoint.md) {
        return "text-h4";
      } else if (this.$vuetify.breakpoint.lg) {
        return "text-h4";
      } else if (this.$vuetify.breakpoint.xl) {
        return "text-xl-h3";
      } else {
        return "text-h3";
      }
    },
    xsBreakpoint() {
      if (this.$vuetify.breakpoint.width <= 430) {
        return "12";
      } else {
        return "6";
      }
    },
    dinamicFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "large";
        case "sm":
          return "large";
        case "md":
          return "x-large";
        case "lg":
          return "x-large";
        case "xl":
          return "x-large";
        case "2xl":
          return "x-large";
      }
    },
    xsOrderBreakpoint() {
      if (this.$vuetify.breakpoint.width <= 599) {
        return "12";
      } else {
        return "6";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#map {
  height: 85vh;
  width: 100%;
  z-index: 0;
}

::v-deep .pulse {
  margin: 100px;
  display: block;
  width: 22px;
  height: 22px;
  background: #ffd8d8;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(216, 216, 216, 0.4);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 184, 184, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 184, 184, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 184, 184, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

::v-deep .leaflet-control-zoom {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 0;

  .leaflet-control-zoom-in {
    width: 60px !important;
    height: 40px;
    border-radius: 10px !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding-top: 5px;
  }

  .leaflet-control-zoom-out {
    width: 60px !important;
    height: 40px;
    border-radius: 10px !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding-top: 6px;
  }
}

/* Override defaults from circle to square */
::v-deep .style-cluster {
  width: 120px !important;
  height: 120px !important;

  .svg-item {
    width: 100%;
    font-size: 16px;
    margin: 0 auto;
  }
}

#app .v-menu__content {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.style--list {
  display: flex !important;
  gap: 10px !important;
  flex-direction: column !important;
}

::v-deep .popups--style {
  background-color: red;
}

::v-deep .trucks-style {
  z-index: 1000 !important;
}

/* Small */
.my-marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}

.my-marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

/* Medium */
.my-marker-cluster-medium {
  background-color: orange;
}

/* Large */
.my-marker-cluster-large {
  background-color: purple;
}

.floating-menu {
  position: absolute;
  top: 30px;
  left: 10px;
  z-index: 10;
  /* Asegúrate de que tenga un valor mayor que el z-index del mapa */
  padding: 10px;
  max-height: calc(100vh - 40px);
  /* Ajusta según sea necesario */
}

/* Ajuste para dispositivos móviles */
@media (max-width: 600px) {
  .floating-menu {
    top: 60px;
    padding: 2px;
  }
}

.proximity-panel {
  max-height: 400px;
  /* Ajusta esta altura según sea necesario */
  overflow-y: auto;
}

@media screen and (max-width: 300px) {
  .responsiveVehiculosDashboard {
    margin-top: 28px;
    /* Ajusta esta altura según sea necesario */
  }
}
</style>
