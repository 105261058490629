export default {
  namespaced: true,
  state: {
    tab: null,
  },

  mutations: {
    setTab(state, payload) {
      state.tab = payload;
    },
    
  },
  actions: {},
};
