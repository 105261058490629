<template>
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="sizes">
    <path
    fill="green" d="M17 6H7C3.69 6 1 8.69 1 12S3.69 18 7 18H17C20.31 18 23 15.31 23 12S20.31 6 17 6M17 16H7C4.79 16 3 14.21 3 12S4.79 8 7 8H17C19.21 8 21 9.79 21 12S19.21 16 17 16M17 9C15.34 9 14 10.34 14 12S15.34 15 17 15 20 13.66 20 12 18.66 9 17 9Z" />
</svg>
</template>
<script>
export default {
  name: 'toggle_on'
}
</script>
<style scoped>
.sizes{
  width: 50px;
  height: 40px;
}
</style>
